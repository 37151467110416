import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { CorporateGovernance, InvestorMobile } from '../../assets/img';
// import { NavigationBar } from '../../components';
// import { Footer } from '../../components/footer';
import { management, executives } from '../../components/main';
import { managementBreadcrumb } from '../../components/main/products';
import { Screen } from '../../styles'

// const Navigation = NavigationBar.Navigation;
const Board = () => {
  const location = useLocation();
  //Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box>
      {/* navigation */}
      {/* <Navigation /> */}
      {/* End of Navigation */}

      {/* Hero Section */}
      <Section className='hero'></Section>
      {/* End of hero section */}
      <BreadCrumb>
        {managementBreadcrumb.map(({ name, path }) => (
          <LinkContainer>
            <Links to={path} className={location.pathname === path && 'active'}>
              {name}
            </Links>{' '}
            <span>/</span>
          </LinkContainer>
        ))}
      </BreadCrumb>

      {/* Senior */}
      <Section className='board' id='board'>
        <Container>
          <Header>Senior Management</Header>
          <Paragraph>
          <p>
            {/* Abbey Mortgage Bank's Executive Committee supports the Chief
            Executive Officer in the day-to-day management of bank and the
            implementation of our strategy. This is overseen by the{' '} */}
            Abbey Mortgage Bank's Senior Management Team Supports the Chief Executive Officer in the day-to-day
            management of bank and the implementation of our strategy. This is overseen by the {' '} 
            <TextLink to='/about/board'>Board of Directors</TextLink>
          </p>
          </Paragraph>
          <SubHeader className='executive'>Executive Management</SubHeader>
          <CardContainer>
            {executives.map(({ img, name, title, path }) => (
              <CardLink to={path}>
                <Card className='executives'>
                  <CardTop className='executives'>{img && <Image src={img} alt='executives' />}</CardTop>
                  <CardBody className='executives'>
                    <p>{name}</p>
                    <span>{title}</span>
                  </CardBody>
                </Card>
              </CardLink>
            ))}
          </CardContainer>
          <SubHeader>Senior Management</SubHeader>
          <CardContainer>
            {management.map(({ img, name, title, path }) => (
              <CardLink to={path}>
                <Card>
                  <CardTop>{img && <Image src={img} alt='management' />}</CardTop>
                  <CardBody>
                    <p>{name}</p>
                    <span>{title}</span>
                  </CardBody>
                </Card>
              </CardLink>
            ))}
          </CardContainer>
        </Container>
      </Section>
      {/* End of senior management */}
      {/* Footer */}
      {/* <Footer /> */}
      {/* End of Footer */}
    </Box>
  );
};

const Box = styled.div`
  width: 100%;
  min-height: 100vh;
  // background-color: #fff;
`;
const Section = styled.div`
  width: 100%;
  &.hero {
    min-height: 50vh;
    background-image: url(${CorporateGovernance});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-top: 80px;
    position: relative;
    ${Screen.mediumpc`
    min-height: 40vh!important;
    `}
    ${Screen.smallpc`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 1rem;
    `}
     ${Screen.tablet`
    min-height: 40vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
     ${Screen.surfaceDuo`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.mediumPhone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.phone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.smallPhone`
    min-height: 30vh;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
  }
  &.board {
    min-height: 100vh;
    padding-top: 7rem;
    ${Screen.miniLargeScreen`
    padding-top: 3rem
    `}
    ${Screen.pad`
    padding-top: 3rem
    `}
    ${Screen.mediumpc`
    padding-top: 5rem
    `}
    ${Screen.smallpc`
    padding-top: 3rem
    `}
    ${Screen.tablet`
    padding-top: 7rem
    `}
     ${Screen.surfaceDuo`
    padding-top: 5rem
    `}
    ${Screen.mediumPhone`
    padding-top: 3rem
    `}
    ${Screen.phone`
    padding-top: 3rem
    `}
    ${Screen.smallPhone`
    padding-top: 3rem
    `}
  }
  &.management {
    min-height: 100vh;
    padding-top: 7rem;
  }
`;
const Container = styled.div`
  width: 80%;
  margin: 0 auto;
  ${Screen.miniLargeScreen`
  width: 80%;
  margin: 5rem auto;
  `}
  ${Screen.pad`
  width: 80%;
  margin: 5rem auto;
  `}
  ${Screen.mediumpc`
  width: 80%;
  margin: 3rem auto;
  `}
  ${Screen.smallpc`
  width: 80%;
  `}
  ${Screen.tablet`
  width: 80%;
  margin: 0 auto!important;
  `}
  ${Screen.surfaceDuo`
  width: 80%;
  margin: 0 auto!important;
  `}
  ${Screen.mediumPhone`
  width: 80%;
  margin: 0 auto!important;
  `}
  ${Screen.phone`
  width: 90%;
  margin: 0 auto!important;
  `}
  ${Screen.smallPhone`
  width: 90%;
  margin: 0 auto!important;
  `}

  &.board {
    
  }
`;
const Header = styled.h1`
  color: #002668;
  font-size: 2rem;
  padding-bottom: 2rem;
  ${Screen.smallpc`
  text-align: center;
  font-size: 1.5rem;
  padding-bottom: 1rem
  `}
  ${Screen.tablet`
  text-align: center;
  font-size: 18px;
  padding-bottom: 2rem
  `}
   ${Screen.surfaceDuo`
  text-align: center;
  font-size: 18px;
  padding-bottom: 1rem
  `}
  /* text-align: center; */
`;
const SubHeader = styled.h3`
color: #002668;
font-size: 1.5rem;
margin-top: 10rem;

  ${Screen.surfaceDuo`
    text-align: center;
  `}

&.executive {
  margin-top: 5rem;
}

${Screen.smallpc`
  font-size: 1.5rem;
  margin-top: 5rem;
  `}
  ${Screen.tablet`
  font-size: 18px;
  `}
`
const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 15px;
  row-gap: 3rem;
  margin-top: 2rem;
  ${Screen.miniLargeScreen`
   display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 10px;
  row-gap: 3rem;
  margin-top: 1rem;
  `}
  ${Screen.pad`
   display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 30px;
  row-gap: 3rem;
  `}
  ${Screen.mediumpc`
   display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 30px;
  row-gap: 3rem;
  `}
  ${Screen.smallpc`
   display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 15px;
  row-gap: 3rem;
  `}
  ${Screen.surfaceDuo`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 3rem;
  row-gap: 1.5rem;
  column-gap: 0;
  `}
  ${Screen.mediumPhone`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1rem;
  row-gap: 1.5rem;
  column-gap: 0;
  `}
  ${Screen.phone`
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 2rem;
  row-gap: 1.5rem;
  column-gap: 0;
  `}
   ${Screen.smallPhone`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1rem;
  row-gap: 1.5rem;
  column-gap: 0;
  `}
`;
const Card = styled.div`
  width: 350px;
  height: 500px;
  background-color: #f5f5f5;

  &.executives {
    width: 450px;
    height: 550px;
  }
  ${Screen.midScreen`
    &.executives {
      width: 400px;
      height: 500px;
    }
    `}
   ${Screen.mediumpc`
    &.executives {
      width: 350px;
      height: 500px;
    }
    `}
  ${Screen.smallpc`
  width: 90%;
  height: 400px;
  &.executives {
    width: 90%;
    height: 400px;
  }
  `}
  ${Screen.surfaceDuo`
  width: 70%;
  height: 400px;
  &.executives {
  width: 70%;
  height: 400px;
  }

  `}
  ${Screen.mediumPhone`
  width: 90%;
  height: 400px;
      &.executives {
  width: 90%;
  height: 400px;
  }
  `}
  ${Screen.phone`
  width: 90%;
  height: 400px;
    &.executives {
  width: 90%;
  height: 400px;
  }
  `}
  ${Screen.smallPhone`
  width: 90%;
  height: 400px;
  `}
  ${Screen.galaxyFold`
  width: 90%;
  height: 350px;
  `}
`;
const CardTop = styled.div`
  width: 100%;
  height: 400px;

  &.executives {
    height: 450px;
  }
  background-color: #d9d9d9;
  ${Screen.midScreen`
    &.executives {
      width: 100%;
      height: 400px;
    }
  `}
  ${Screen.smallpc`
  width: 100%;
  height: 300px;
    &.executives {
    width: 100%;
    height: 300px;
  }
  `}
  ${Screen.mediumPhone`
  width: 100%;
  height: 300px;
  `}
  ${Screen.phone`
  width: 100%;
  height: 300px;

  `}
  ${Screen.smallPhone`
  width: 100%;
  height: 300px;
  `}
  ${Screen.galaxyFold`
  width: 100%;
  height: 250px;
  `}
`;
const CardBody = styled.div`
  width: 100%;
  height: 70px;
  text-align: center;
  padding-top: 1rem;

  &.executives {
    height: 100px;
  }
  ${Screen.midScreen`
    &.executives {
      width: 100%;
      height: 100px;
    }
  `}
  ${Screen.mediumPhone`
  padding-top: .5rem
  `}
  ${Screen.phone`
  padding-top: .5rem;
   &.executives {
    height: 100px;
  }
  `}
  ${Screen.smallPhone`
  padding-top: .5rem
  `}

  p {
      font-weight: bold;
      padding-top: 1rem;
      font-size: 1rem;
      padding-bottom: 0.5rem;
      ${Screen.mediumPhone`
    font-size: 14px
    `}
      ${Screen.phone`
    font-size: 14px
    `}
    ${Screen.smallPhone`
    font-size: 14px
    `}
  }

  span {
      text-transform: uppercase;
      font-size: 0.8rem;
      ${Screen.mediumPhone`
      font-size: 12px;
      padding: 0 .5rem;
      `}
      ${Screen.phone`
      font-size: 12px;
      padding: 0 .5rem;
      `}
      ${Screen.smallPhone`
      font-size: 12px;
      padding: 0 .5rem;
      `}
    }
`;
const TextLink = styled(Link)`
  color: #000;
  ${Screen.mediumPhone`
  font-size: 14px
  `}
  ${Screen.phone`
  font-size: 14px
  `}
  ${Screen.smallPhone`
  font-size: 14px
  `}
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
`;
const CardLink = styled(Link)`
  color: #000;
  text-decoration: none;
  /* ${Screen.smallPhone`
  margin-bottom: 1rem;
  `} */
`;
const BreadCrumb = styled.div`
  width: 100%;
  height: 3rem;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  padding-left: 5rem;
  /* position: fixed; */

  span {
    font-weight: bold;
    padding-right: 0.5rem;
    ${Screen.mediumPhone`
    font-size: 10px;
    `}
  }

  ${Screen.surfaceDuo`
  padding-left: 10px;
  `}
  ${Screen.mediumPhone`
  padding-left: 10px;
  `}
  ${Screen.phone`
  padding-left: 10px;
  `}
  ${Screen.smallPhone`
  padding-left: 10px;
  `}
  ${Screen.galaxyFold`
  padding-left: 5px;
  `}
`;
const Links = styled(Link)`
  color: #002668;
  text-decoration: none;
  font-weight: bold;
  padding-right: 0.3rem;

  &.active {
    color: #c81e19;
  }
  ${Screen.mediumPhone`
  padding-right: 0!important;
  `}
  ${Screen.phone`
  padding-right: 0!important;
  `}
  ${Screen.smallPhone`
  padding-right: 0!important;
  `}
`;
const LinkContainer = styled.p`
${Screen.surfaceDuo`
font-size: 14px;
word-break: keep-all!important;
`}
${Screen.mediumPhone`
font-size: 11px;
word-break: keep-all!important;
`}
${Screen.phone`
font-size: 10px;
word-break: keep-all!important;
`}
${Screen.samsungGalaxy`
font-size: 10px;
word-break: keep-all!important;
`}
${Screen.smallPhone`
font-size: 8px;
word-break: keep-all!important;
`}
${Screen.galaxyFold`
font-size: 7px;
word-break: keep-all!important;
`}
`;

const Paragraph = styled.div`
p {
  ${Screen.mediumPhone`
  font-size: 14px;
  `}
  ${Screen.phone`
  font-size: 14px;
  `}
  ${Screen.smallPhone`
  font-size: 14px;
  `}
}
`;
export default Board;
