import {
  Vision,
  Mission,
  Member3,
  Member4,
  Blog,
  Customer,
  Director,
  Management,
  Facebook,
  LinkedIn,
  Twitter,
  Instagram,
  InterestOne,
  InterestTwo,
  Card,
  Reflect,
  Prepaid,
  AbbeyPlus,
  BloomTeens,
  FixedDeposit,
  Mask,
  AbbeyBiz,
  OpenAccount,
  ApplyMortgage,
  GetEquity,
  CardServices,
  Phone,
  SavingAccount,
  BloomKids,
  AbbeyZoomers,
  CurrentAccount,
  BizExtra,
  QuickDial,
  Mobile,
  LifestyleOne,
  LifestyleTwo,
  LifestyleThree,
  LifestyleFour,
  LifestyleFive,
  LifestyleSix,
  LifestyleSeven,
  LifestyleEight,
  LifestyleNine,
  LifestyleTen,
  LifestyleEleven,
  LifestyleTwelve,
  LifestyleThirteen,
  OniImg,
  ObasaImg,
  UmegoImg,
  Adenike,
  Christabel,
  Jewel,
  Obinna,
  Dips,
  Beejay,
  LasisiImg,
  OlusolaImg,
  ToyinImg,
  TosinImg,
  BukolaImg,
  FelixImg,
  FunmiImg,
  TomiImg,
  IndogesitImg,
  EbenezerImg,
  BolajiImgNew,
  DipoImgNew,
  LolitaImgNew,
  GeoffImgNew,
  ChikaImg,
  IdentityImg
  // Nonso,
} from '../../assets/img';
import {
  AbbeyIcon,
  COG,
  Education,
  Environment,
  Events,
  Financial,
  Fixed,
  Forms,
  Investor,
  Medical,
  MoreIcon,
  Notice,
  Press,
  Publication,
} from '../../assets/svg';

export const brand = [
  {
    img: Vision,
    title: 'Our Vision',
    content: `To be the Leading Mortgage Bank out of Africa`,
  },
  {
    img: Mission,
    title: 'Our Mission',
    content: `To create opportunities to become the First Choice 
    for Mortgages, Housing, Finance & Investment`,
  },
  {
    title: 'Our Core Values',
    list: [
      'Supportive',
      'Professional',
      'Innovative',
      'Customer-Focused',
      'Excellent',
    ],
    // `Supportive Professional Innovative Customer-Focused Excellent`,
  },
];

export const executives = [
  {
    img: BolajiImgNew,
    name: 'Mobolaji Adewumi',
    title: 'Chief Executive Officer/Managing Director',
    path: '/profile/mobolaji-adewumi',
  },
  {
    img: DipoImgNew,
    name: 'Dipo Adeoye',
    title: 'Executive Director, Treasury & Business Growth',
    path: '/profile/oladipupo-ayodele',
  },
  // {
  //   img: LolitaImgNew,
  //   name: 'Lolita Ejiofor',
  //   title: 'GM/Group Head IT & Operations',
  //   path: '/profile/lolita-ejiofor',
  // },
  // {
  //   img: GeoffImgNew,
  //   name: 'Geoff O. Amaghereomu',
  //   title: 'Legal Advisor/Company Secretary',
  //   path: '/profile/geoff-amaghereonu',
  // },
];

export const management = [
  {
    img: LasisiImg,
    name: 'Abiodun Lasisi',
    title: 'Head, Loan Monitoring & Debt Recovery',
    path: '/profile/abiodun-lasisi',
  },
  {
    img: OlusolaImg,
    name: 'Olushola Seidu',
    title: 'Group Head, Internal Control & Compliance/CISO',
    path: '/profile/olushola-seidu',
  },
  {
    img: ToyinImg,
    name: 'Toyin Abel',
    title: 'Group Head, Private Banking & General Commerce',
    path: '/profile/toyin-abel',
  },
  {
    img: TosinImg,
    name: 'Tosin Beredugo',
    title: 'Head, information technology',
    path: '/profile/tosin-beredugo',
  },
  // {
  //   img: BukolaImg,
  //   name: 'Bukola Ewedairo',
  //   title: 'Group Head, Developmental Organizations & Partnerships',
  //   path: '/profile/bukola-ewedairo',
  // },
  {
    img: FelixImg,
    name: 'Felix Omodayo-Owotuga',
    title: 'Group Head, Retail Sales & E-business',
    path: '/profile/felix-omodayo-owotuga',
  },
  {
    img: FunmiImg,
    name: 'Funmi Femi Omage',
    title: 'Head, Operations',
    path: '/profile/funmi-femi-omage',
  },
  {
    img: TomiImg,
    name: 'Oluwatomi Olurinola',
    title: 'Head, Financial Control',
    path: '/profile/oluwatomi-olurinola',
  },
  {
    img: IndogesitImg,
    name: 'Idongesit Abia',
    title: 'Head, Credit & Enterprise Risk Management',
    path: '/profile/idongesit-abia',
  },
  {
    img: EbenezerImg,
    name: 'Ebenezer Afolabi',
    title: 'Head, Internal Audit',
    path: '/profile/ebenezer-afolabi',
  },
];

export const relations = [
  {
    img: COG,
    link: 'Corporate Governance',
  },
  {
    img: Financial,
    link: 'Financial Information',
  },
  {
    img: Forms,
    link: 'Forms',
  },
  {
    img: Investor,
    link: 'Investor News',
  },
  {
    img: Events,
    link: 'Events',
  },
];

export const press = [
  {
    img: Customer,
    desc: `CSWeek 2022: Abbey Mortgage Bank Celebrates 3 Decades of Excellent Customer Service.`,
  },
  {
    img: Director,
    desc: `Abbey continues its transformative journey to growth 30 years in.`,
  },
  {
    img: Management,
    desc: `Abbey Mortgage Bank records N76 million in profit for Q1 2021`,
  },
];

export const blog = [
  {
    img: Blog,
    title: 'Get more out of Your Mortgage',
    content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporin cididunt ut labore et dolore magna aliqua`,
  },
  {
    img: Blog,
    title: 'How to Develop a Saving Culture',
    content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporin cididunt ut labore et dolore magna aliqua`,
  },
  {
    img: Blog,
    title: 'Start Planning for your Dream home today',
    content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporin cididunt ut labore et dolore magna aliqua`,
  },
];

export const corporate = [
  {
    header: 'Information Title Goes Here',
    content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod 
    tempor incididunt ut labore et dolore magna aliqua.`,
  },
  {
    header: 'Information Title Goes Here',
    content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod 
    tempor incididunt ut labore et dolore magna aliqua.`,
  },
  {
    header: 'Information Title Goes Here',
    content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod 
    tempor incididunt ut labore et dolore magna aliqua.`,
  },
];

export const products = [
  {
    img: AbbeyBiz,
    header: 'Abbey Biz',
    content: `An account that provides affordable and convenient ways 
    to run your business, and financial options tailored to your 
    corporate needs, 
    `,
    path: '/business/abbey-biz',
  },
  {
    img: BizExtra,
    header: 'Biz Extra',
    content: `A hybrid or both savings and current account 
    features which give your business the best of both worlds.
    `,
    path: '/business/biz-extra',
  },
  {
    img: FixedDeposit,
    header: 'Fixed Deposit',
    content: `A Money market account for individuals and corporate 
    bodies that want to keep funds for future use, while they 
    enjoy reasonable returns on their deposit.
    `,
    path: '/business/fixed-deposit',
  },
];

export const environment = [
  {
    img: Vision,
    text: 'Title One',
  },
  {
    img: Vision,
    text: 'Title Two',
  },
  {
    img: Vision,
    text: 'Title Three',
  },
];

export const recruitment = [
  {
    num: '01',
    title: 'Title Goes Here',
    content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt`,
  },
  {
    num: '02',
    title: 'Title Goes Here',
    content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt`,
  },
  {
    num: '03',
    title: 'Title Goes Here',
    content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt`,
  },
];

export const investor = [
  {
    img: Blog,
    title: 'Press Releases:',
  },
  {
    img: Blog,
    title: 'Notices:',
  },
  {
    img: Blog,
    title: 'Publications:',
  },
  {
    img: Blog,
    title: 'Publications:',
  },
];

export const analyst = [
  {
    img: Blog,
    text: 'Report 1',
  },
  {
    img: Blog,
    text: 'Presentation 1',
  },
  {
    img: Blog,
    text: 'Report 2',
  },
  {
    img: Blog,
    text: 'Presentation 2',
  },
  {
    img: Blog,
    text: 'Report 3',
  },
  {
    img: Blog,
    text: 'Presentation 3',
  },
  {
    img: Blog,
    text: 'Report 4',
  },
  {
    img: Blog,
    text: 'Presentation 4',
  },
  {
    img: Blog,
    text: 'Report 5',
  },
  {
    img: Blog,
    text: 'Presentation 5',
  },
  {
    img: Blog,
    text: 'Report 6',
  },
  {
    img: Blog,
    text: 'Presentation 6',
  },
];

export const shareholder = [
  {
    text: `Registra's Details`,
  },
  {
    text: `E-dividend`,
  },
  {
    text: `Agm form`,
  },
  {
    text: `Press releases`,
  },
  {
    text: `Notices`,
  },
  {
    text: `Management Policies`,
  },
];

export const icons = [
  {
    img: Facebook,
  },
  {
    img: Instagram,
  },
  {
    img: Twitter,
  },
  {
    img: LinkedIn,
  },
];

export const lagos = [
  {
    name: 'Victoria Island',
    address: '23, Karimu Kotun  street, Victoria Island, Lagos',
  },
  {
    name: 'Festac',
    address: `House 12, 2 Avenue, Festac Town, Amuwo Odofin, Lagos`,
  },
  {
    name: 'Apapa',
    address: `No 19, Warehouse Road, Apapa, Lagos `,
  },
  {
    name: 'Okota',
    address: `No 51, Okota Road, Okota, Isolo, Lagos`,
  },
  {
    name: 'Iba',
    address: `KM 5, Lasu-Isheri Road, By Agbokoro Bus Stop, Iba, Lagos`,
  },
];

export const abuja = [
  {
    name: 'Abuja',
    address: 'No 1, Ayangba Close, Area II, Garki, Abuja, FCT',
  },
  {
    name: 'Baze',
    address:
      '686, Cadastral Zone C00, Kuchigoro, Behind Judicial Institute, Abuja, FCT',
  },
];

export const directors = [
  {
    name: 'High Chief Samuel Oni',
    position: 'Chairman',
    path: '/profile/samuel-oni',
    img: OniImg
  },
  {
    name: 'Mobolaji Adewumi',
    position: 'Chief Executive Officer/Managing Director',
    img: Beejay,
    path: '/profile/mobolaji-adewumi'
  },
  {
    name: 'Oladipo Adeoye',
    position: 'Executive Director, Treasury & Business Growth',
    img: Dips,
    path: '/profile/oladipupo-ayodele',
  },
  {
    name: 'Brig-Gen John Obasa',
    position: 'Non-Executive Director',
    path: '/profile/john-obasa',
    img: ObasaImg
  },
  {
    name: 'Prof. Marius Umego',
    position: 'Non-Executive Director',
    path: '/profile/marius-umego',
    img: UmegoImg
  },
  {
    name: 'Obinna Ufudo',
    position: 'Non-Executive Director',
    path: '/profile/obinna-ufudo',
    img: Obinna
  },
  {
    name: 'Cristabel Onyejekwe',
    position: 'Non-Executive Director',
    path: '/profile/christabel-onyejekwe',
    img: Christabel
  },
  {
    name: 'Jewel Okwechime',
    position: 'Non-Executive Director',
    path: '/profile/jewel-okwechime',
    img: Jewel
  },
  {
    name: 'Chika Ochonogor',
    position: 'Non-Executive Director',
    path: '/profile/chika-ochonogor',
    img: ChikaImg
  },
  {
    name: 'Adenike Kuti',
    position: 'Non-Executive Director',
    path: '/profile/adenike-kuti',
    img: Adenike
  },
  // {
  //   name: 'Nonso Okpala',
  //   position: 'Non-Executive Director',
  //   path: '/profile/nonso-okpala',
  //   img: NonsoImg
  // },
];

export const breakpoints = [
  {
    width: 1,
    itemsToShow: 1,
  },
  {
    width: 768,
    itemsToShow: 1,
  },
  {
    width: 790,
    itemsToShow: 2,
  },
  {
    width: 900,
    itemsToShow: 4,
  },
  {
    width: 1000,
    itemsToShow: 4,
  },
  {
    width: 1200,
    itemsToShow: 4,
  },
];

export const quickLinks = [
  {
    icon: OpenAccount,
    name: 'Open Account',
    path: '/personal-form',
    className: 'openAccount',
  },
  {
    icon: ApplyMortgage,
    name: 'Apply for Mortgage',
    path: '/loans/form',
    className: 'applyMortgage',
  },
  {
    icon: GetEquity,
    name: 'Get Equity Release',
    path: '/loans/form',
    className: 'equityRelease',
  },
  {
    icon: CardServices,
    name: 'Card Services',
    path: '/ways-to-bank',
    className: 'cardServices',
  },
  {
    icon: Fixed,
    name: 'Set up Fixed Deposit',
    path: '/personal/fixed-deposit',
    className: 'fixedDeposit',
  },
  {
    icon: IdentityImg,
    name: 'Link your BVN and NIN',
    path: 'https://identity.abbeymortgagebank.com',
    className: 'identity',
  },
  {
    icon: Phone,
    name: 'Contact Us',
    path: '/help',
    className: 'contactUs',
  },
];

export const personalProducts = [
  {
    icon: AbbeyIcon,
    title: 'Personal',
    description: `Whatever your needs are, we have the account just right for you. `,
    path: '/personal',
  },
  {
    icon: AbbeyIcon,
    title: 'Mortgages',
    description: `Planning to buy a new home? 
    Our specialized mortgages will make your dreams a reality.`,
    path: '/loans#mortgage-loan',
    // path: '/#mortgage-loan',
  },
  {
    icon: AbbeyIcon,
    title: 'Bank with Abbey',
    description: `Bank with ease and sign up to our digital banking services today.`,
    path: '/ways-to-bank',
  },
];

export const interest = [
  {
    img: InterestOne,
    title: 'How to Develop a Saving Culture',
    description: `Saving a little every day, can make all the 
    difference tomorrow. Here are some tips to help you get 
    started in cultivating a Saving Culture...`,
  },
  {
    img: InterestTwo,
    title: 'How to get more out of your mortgage',
    description: `Contrary to popular belief, Mortgages do exist 
    in Nigeria and have for some time. Everyday, people kickstart 
    their real estate ventures with mortgage opportunities...`,
    path: '#',
  },
];

export const waysLinks = [
  {
    img: Card,
    name: 'Abbey Cards',
    path: '/ways-to-bank/abbey-cards',
  },
  {
    img: QuickDial,
    name: '*5103#',
    path: '/ways-to-bank/quick-dial',
  },
  {
    img: Mobile,
    name: 'Abbey Mobile & Abbey CIB',
    path: '/ways-to-bank/abbey-mobile',
  },
];

export const waysProducts = [
  {
    img: Card,
    title: 'Debit Cards',
    content: `Abbey Mortgage Bank Debit Cards ensure 24/7 
    access to your account anywhere and whenever in Nigeria. 
    Make daily purchases and pay your bills without carrying 
    cash. It's far more convenient and safer than cash.`,
  },
  {
    img: Reflect,
    title: 'Reflect Yourself Cards',
    content: `You have the option to give your card a 
    distinctive appearance and feel with the Abbey Reflect 
    Yourself Card. Showcase your family, a pet, or your 
    favourite picture and have the picture printed on your 
    debit or prepaid cards.`,
  },
  {
    img: Prepaid,
    title: 'Prepaid Cards',
    content: `Get a Prepaid Card and get better at your 
    financial management. Top up money, pay bills and 
    manage your spending wisely.`,
  },
];

export const quickDials = [
  {
    title: 'Balance Check',
    code: '*5103#',
  },
  {
    title: 'Airtime Self Top-up',
    code: '*5103*Amount#',
  },
  {
    title: 'Airtime 3rd Party Top-up',
    code: '*5103*Amount*Beneficiary_Phone_Number#',
  },
  {
    title: 'Transfer to Abbey Account',
    code: '*5103*1*Amount*Beneficiary_Account#',
  },
  {
    title: 'Transfer to Other Banks',
    code: '*5103*2*Amount*Beneficiary_Account_Number#',
  },
  {
    title: 'Pays bills',
    code: '*5103*3#',
  },
  {
    title: 'Data Purchase - Self',
    code: '*5103*4#',
  },
  {
    title: 'Data Purchase - 3rd Party',
    code: '*5103*5#',
  },
  {
    title: 'Block Account',
    code: '*5103*6#',
  },
];

export const abbeyMobileNew = [
  {
    name: 'Download and Install the AbbeyMobile app',
  },
  {
    name: `Click on “Create Account” to register.`,
  },
  {
    name: 'Select New Customer',
  },
  {
    name: 'Enter your phone number and date of birth (Referral code is optional).',
  },
  {
    name: 'Select either BVN or Bank Account (Bank account number with any other bank)',
  },
  {
    name: 'Enter your BVN Number or existing account number with any bank.',
  },
  {
    name: 'Accept Terms and Conditions.',
  },
  {
    name: 'An OTP would be sent to the mobile number link to the BVN/Account Number inputted',
  },
  {
    name: 'Enter OTP',
  },
  {
    name: 'Enter email address and create your preferred password.',
  },
  {
    name: 'Capture your Face ID and signature',
  },
  {
    name: 'Create a transaction PIN.',
  },
  {
    name: 'Registration is complete',
  },
];

export const abbeyMobileExisting = [
  {
    name: 'Download and Install the AbbeyMobile app',
  },
  {
    name: `Click on “Create Account” to register.`,
  },
  {
    name: 'Click on “Existing Account”.',
  },
  {
    name: 'Enter Abbey account number and Accept Terms and Conditions.',
  },
  {
    name: 'An OTP would be sent via SMS to the registered phone number.',
  },
  {
    name: 'Enter OTP and email address.',
  },
  {
    name: 'Capture your Face ID and signature.',
  },
  {
    name: 'Create password and transaction PIN.',
  },
  {
    name: 'Registration is complete',
  },
];

export const whatsNew = [
  {
    name: 'Open Abbey Bank Account',
  },
  {
    name: 'Access your Abbey Accounts',
  },
  {
    name: 'Send Money to any Abbey Bank account',
  },
  {
    name: 'Send Money to other banks',
  },
  {
    name: 'Airtime and Bills Payments',
  },
  {
    name: 'Request Mortgage Loans',
  },
  {
    name: 'View transaction history, monthly trend, and request for account statements.',
  },
  {
    name: 'Open Save to Own Account.',
  },
  {
    name: 'Fixed deposit and Investment',
  },
  {
    name: 'Hide/show account balance',
  },
  {
    name: 'Manage Beneficiaries',
  },
];

export const cib = [
  {
    item: 'Track your expenses'
  },
  {
    item: 'Streamline bulk payments'
  },
  {
    item: 'Instantly download bank statements'
  }
]

export const privacyThree = [
  {
    item: `you open an account or perform transactions 
    such as make deposits or withdrawals from your account, payment 
    history and transactions records.`,
  },
  {
    item: `you apply for a loan or use your credit or debit card.`,
  },
  {
    item: `you seek advice about your investments.`,
  },
  {
    item: `you seek information from our customer service provider,
    information concerning complaints and disputes.`,
  },
  {
    item: `we seek information about your credit history from 
    credit bureaus.`,
  },
  {
    item: `you provide account information such as your personal 
    details e.g., name, gender, date, and place of birth; 
    contact information such as address, email address, and 
    mobile numbers, provide your employment information.`,
  },
  {
    item: `you provide information concerning your identity 
    e.g., photo ID, passport information, National ID card and 
    nationality.`,
  },
  {
    item: `you use your login credentials for online banking 
    and mobile banking apps.`,
  },
  {
    item: `we conduct necessary investigations i.e., due diligence
    checks, and AML/CFT checks and obtain information that we 
    need to support our regulatory obligations, e.g., 
    information about transaction details, detection of any 
    suspicious and unusual activities.`,
  },
];

export const privacyFour = [
  {
    item: `Abbey Mortgage Bank Plc will only use your 
    information when you have provided your consent or 
    when the Bank is required by the law to do so.`,
  },
  {
    item: `We use the information we collect to provide 
    customers with excellent products and services, 
    to manage our business and to offer an enriched 
    and enhanced customer experience.`,
  },
  {
    item: `We make appropriate use of your data to manage 
    transactions, respond to your requests, and to 
    provide you with more relevant products and 
    services.`,
  },
  {
    item: `We use your information to deliver 
    our products and services, carry out your 
    instructions, and provide Online Banking, mobile 
    banking and other online product and services.`,
  },
  {
    item: `We use this information to detect and prevent 
    financial crimes including fraud, financing of 
    terrorism and money laundering, this is to ensure 
    security and business continuity.`,
  },
  {
    item: `We will use 
    your information to meet our compliance 
    obligations, to comply with laws and regulations 
    and to share with regulators when necessary.`,
  },
  {
    item: `We may also use personal information we have 
    about you such as your email address to deliver 
    advertising to you directly or on our websites, 
    provide updates on special deals and offers that 
    might interest you (unless you tell us otherwise).`,
  },
  {
    item: `We may send you general announcements or 
    important news about your account, request your 
    feedback and we believe you should be able to 
    select what kinds of information you receive from 
    us via email.`,
  },
  {
    item: `We may need to record 
    conversations you have with us including phone 
    calls, face-to-face meetings, letters, emails, 
    and any other kinds of communication. These 
    recordings may be used to check your instructions 
    to us and improve on our product and service 
    delivery.`,
  },
];

export const privacyFive = [
  {
    header: 'The right to be informed',
    content: `To emphasize the need for transparency over the usage of personal data,
    we ensure fair processing of information typically through this privacy policy.`,
  },
  {
    header: 'The rights to access',
    content: `Individuals ahve the right to access the information the Bank holds,
    access their personal data and other supplementary information and obtain information
    about how we process it.`,
  },
  {
    header: 'The right to restrict processing',
    content: `Individuals have a right to 'block' or withdraw their
    consent to our processing of their information, which they can
    do at any time. When processing is restricted, we are permitted
    to store the personal data, but not further process it.`,
  },
  {
    header: 'The right to rectification',
    content: `Individuals are entitled to have personal data rectified
    if it is inaccurate or incomplete. If this personal data in question
    has been disclosed to third parties, they must be informed of the
    rectification where possible. The Bank must also inform the individuals
    about the third parties to whom the data has been disclosed where appropriate.`,
  },
  {
    header: 'The right to erasure',
    content: `Individuals have the right to request the deletion or removal of personal
    data where there is no compelling legal or regulatory requirement for its continued
    processing. The Bank will make sure that this right is protected.`,
  },
  {
    header: 'The right to data portability',
    content: `We will ensure that personal data is moved, copied, or transferred easily
    from one IT environment to another in a safe and secure way, without hindrance to 
    usability.`,
  },
  {
    header: 'The right to object',
    content: `Individuals have the right to object to our processing of their information
    in some circumstances.`,
  },
];

export const terms = [
  {
    item: 'Force majeure',
  },
  {
    item: 'Labour Strike Actions',
  },
  {
    item: 'Hardware failure',
  },
  {
    item: 'Software failuree',
  },
  {
    item: 'Overload of system capacities',
  },
  {
    item: 'Interruption of power supply or other utilities',
  },
  {
    item: `Government or regulatory restrictions, court or 
    tribunal rulings, amendment of legislation or other human 
    intervention`,
  },
  {
    item: 'Any other cause whatsoever beyond the control of Abbey',
  },
];

export const announcement = [
  {
    img: Press,
    name: 'Press Releases',
  },
  {
    img: Publication,
    name: 'Publications',
  },
  {
    img: Notice,
    name: 'Notices',
  },
];

export const product = [
  {
    img: Mask,
    header: 'Abbey Save',
    content: `Open an Abbey Save account today, 
    a little at a time, so we get to know you.`,
    path: '/personal/abbey-save',
  },
  {
    img: AbbeyPlus,
    header: 'Abbey Plus',
    content: `Save even more for benefits without stress`,
    path: '/personal/abbey-plus',
  },
  {
    img: SavingAccount,
    header: 'Savings Account',
    content: `Save and earn to bring you even closer to 
    your goals and financial freedom.`,
    path: '/personal/savings-account',
  },
  {
    img: BloomKids,
    header: 'Bloom Kidz',
    content: `Childrens account for kids under 13 years old. 
    Show them the early birds get the fattest worm!`,
    path: '/personal/bloom-kidz',
  },
  {
    img: BloomTeens,
    header: 'Bloom Teens',
    content: `Teens starting early? We have this cool account for cool teens 
    between ages of 13-18`,
    path: '/personal/bloom-teens',
  },
  {
    img: AbbeyZoomers,
    header: 'Abbey Zoomers',
    content: `An account specially for the savvy savers ages between 18-30 years old.
    These young savers will be in control, for sure.`,
    path: '/personal/abbey-zoomers',
  },
  {
    img: CurrentAccount,
    header: 'Current Account',
    content: `This Abbey account that provides an 
    affordable and convenient way to smoothly run your 
    finances.`,
    path: '/personal/current-account',
  },
  {
    img: FixedDeposit,
    header: 'Fixed Deposit',
    content: `Enjoy competitive interests and earn better 
    returns your hard earned money deserves.`,
    path: '/personal/fixed-deposit',
  },
];

export const focus = [
  {
    icon: Education,
    title: 'Education',
    content: `At Abbey, we believe that education is a 
    fundamental right for every child, so we work to 
    enable schools provide the right tools for educational 
    needs.`,
  },
  {
    icon: Medical,
    title: 'Health',
    content: `We believe health is wealth, so we feel 
    passionate about supporting hospitals obtain life 
    saving equipment for appropriate medical care.`,
  },
  {
    icon: Environment,
    title: 'Environment',
    content: `We have started and plan to continue to 
    advocate for changes for a more sustainable 
    environment by raising awareness and changing how we 
    work.`,
  },
  {
    icon: Education,
    title: 'Art',
    content: `Abbey Mortgage Bank values the cultural and societal impact of the arts. 
    We support artistic endeavors that foster creativity, 
    preserve cultural heritage, and enrich communities.`
  },
  {
    icon: Medical,
    title: 'Housing',
    content: `As a leader in the mortgage industry, Abbey Mortgage Bank is dedicated to 
    making safe and affordable housing accessible. We work to create opportunities for 
    everyone to secure a stable and vibrant home.
    `
  },
  {
    icon: Environment,
    title: 'Sport',
    content: `Abbey Mortgage Bank recognizes the importance of sports in building community
    spirit and fostering healthy living. We support sports initiatives the inspire teamwork,
    resilience, and unity.
    `
  },
];
export const mapLinks = [
  {
    item: 'Victoria Island, Lagos',
    coord: {
      lat: 6.429740702222439,
      lng: 3.4253751637646426,
    },
    address: '23, Karimu Kotun Street, Victoria Island'
  },
  {
    item: 'Festac, Lagos',
    coord: {
      lat: 6.462623,
      lng: 3.286578,
    },
    address: 'House 18, 2 Avenue, Festac Town, Amuwo Odofin'
  },
  {
    item: 'Apapa, Lagos',
    coord: {
      lat: 6.440015139882757,
      lng: 3.3693586393764776 
    },
    address: '19, Warehouse road, Apapa, Lagos State'
  },
  {
    item: 'Okota, Lagos',
    coord: {
      lat: 6.519995048502703,
      lng: 3.3179660201720806,
    },
    address: '15, Okota road, Okota, Isolo, Lagos State'
  },
  {
    item: 'Iba',
    coord: {
      lat: 6.5064861,
      lng: 3.200731,
    },
    address: 'KM5, Lasu Isheri road, Agbokoro bus stop, Iba, Lagos State'
  },
  {
    item: 'Abuja, FCT',
    coord: {
      lat: 9.0464142,
      lng: 7.4963961,
    },
    address: 'No 1, Ayangba Close, Area II, Garki, Abuja, FCT'
  },
  {
    item: 'Baze University, FCT',
    coord: {
      lat: 9.0621,
      lng: 7.39563,
    },
    address: '686, Cadastal zone COO, Kuchigoro, behind National Judicial Institute, Abuja, FCT'
  },
  {
    item: 'Asaba, Delta',
    coord: {
      lat: 6.206373828082899,
      lng: 6.717330955941039,
    },
    address: '375, Nnebisi road, Asaba, Delta State'
  }
];
export const moreItems = [
  {
    img: MoreIcon,
    header: 'Our Story',
    content: `Understanding the many challenges faced by most Nigerians 
    when it comes to homeownership, Rose Ada Okwechime founded the bank 
    in 1991. Then known as Abbey Building Society Plc, the bank was 
    established...`,
    path: '/about/story',
  },
  {
    img: MoreIcon,
    header: 'Leadership and Governance',
    content: `Abbey Mortgage Bank is lead by a capable team of people 
    with a rich history in banking and finance.`,
    path: '/about/leadership-and-governance',
  },
  {
    img: MoreIcon,
    header: 'Investor Relations',
    content: `Our bank attracts a variety of customers and staff 
    member, who contribute to the transformative growth have seen in 
    recent years.`,
    path: '/investor-relations',
  },
];

export const gallery = [
  {
    img: LifestyleOne,
  },
  {
    img: LifestyleTwo,
  },
  {
    img: LifestyleThree,
  },
  {
    img: LifestyleFour,
  },
  {
    img: LifestyleFive,
  },
  {
    img: LifestyleSix,
  },
  {
    img: LifestyleSeven,
  },
  {
    img: LifestyleEight,
  },
  {
    img: LifestyleNine,
  },
  {
    img: LifestyleTen,
  },
  {
    img: LifestyleEleven,
  },
  {
    img: LifestyleTwelve,
  },
  {
    img: LifestyleThirteen,
  },
];

export const recruitmentItem = [
  {
    number: '01',
    header: 'Preparing your CV',
    content: `Your CV is your first opportunity to 
    demonstrate your qualities and achievements.  
    In addition to your CV we may ask you to complete an 
    application form.`,
  },
  {
    number: '02',
    header: 'Online Assessment',
    content: `Following your application, we may send you a 
    link to a website containing some online assessments, 
    designed to identify your suitability for the role. 
    Assessments will depend on the role for which you are 
    applying.`,
  },
  {
    number: '03',
    header: 'Interviews',
    content: `We assess your compatibility with Abbey’s 
    values, as well as your general skills and motivation 
    for applying. It is also your opportunity to make sure 
    the interviewer knows that you are enthusiastic about 
    working at Abbey.`,
  },
];

export const mediaItem = [
  {
    views: '1000 views',
    header: `Let's gist about fixed deposits`,
    date: 'October 14, 2022',
    likes: '100 likes',
    comment: '20 comments',
    user: 'Admin',
  },
  {
    views: '1000 views',
    header: `Let's gist about fixed deposits`,
    date: 'October 14, 2022',
    likes: '100 likes',
    comment: '20 comments',
    user: 'Admin',
  },
  {
    views: '1000 views',
    header: `Let's gist about fixed deposits`,
    date: 'October 14, 2022',
    likes: '100 likes',
    comment: '20 comments',
    user: 'Admin',
  },
];

export const categoriesItem = [
  {
    item: 'Mortgages',
  },
  {
    item: 'Money Management',
  },
  {
    item: 'Wealth Building',
  },
  {
    item: 'Investment Opportunities',
  },
  {
    item: 'Real Estate',
  },
  {
    item: 'News',
  },
];

export const careerImages = [
  {
    img: LifestyleOne
  },
  {
    img: LifestyleTwo
  },
  {
    img: LifestyleThree
  },
  {
    img: LifestyleFour
  },
  {
    img: LifestyleFive
  },
  {
    img: LifestyleSix
  },
  {
    img: LifestyleSeven
  },
  {
    img: LifestyleEight
  },
  {
    img: LifestyleNine
  },
  {
    img: LifestyleTen
  },
  {
    img: LifestyleEleven
  },
  {
    img: LifestyleTwelve
  },
  {
    img: LifestyleThirteen
  },
]