import { useEffect, useContext, useRef, useState } from 'react';
import styled from 'styled-components';
import { HelpersComponent } from '../../components';
import { NavButton } from '../../styles';
// import api from '../../services/api';
// import { Footer } from '../../components/footer';
import { PersonalImg1, PersonalMobile } from '../../assets/img';
import { Link, useNavigate } from 'react-router-dom';
import { product } from '../../components/main';
import { PersonalHeroContext } from '../../contexts/hero'
import { HERO_FAILURE, HERO_REQUEST, HERO_SUCCESS } from '../../constants';
import api from '../../services/api';
import { Screen } from '../../styles'
import Carousel from "@itseasy21/react-elastic-carousel"
import "../../carousel.css"

// const Navigation = NavigationBar.Navigation;
const ComponentLoader = HelpersComponent.dashboardLoader;
const Personal = () => {
  const mount = useRef()
  const { dispatch, state } = useContext(PersonalHeroContext)
  const [mobile, setMobile] = useState(false)
  // const carouselRef = useRef(null);
  // let resetTimeout;

  const responsive = () => {
    return window.innerWidth <= 900 ? setMobile(true) : setMobile(false)
  }
  useEffect(() => {
    mount.current = true
    //Scroll to top on page load
    window.scrollTo(0, 0);

    responsive()
    window.addEventListener('resize', () => responsive())

    // get Hero
    const getHero = async () => {
      try {
        if(state.data.length < 1){
          dispatch({ type: HERO_REQUEST })
          const result = await api.req('/api/document/hero/personal')
          dispatch({
            type: HERO_SUCCESS,
            payload: JSON.parse(result.details.data)
          })
        }
      } catch (error) {
        dispatch({
          type: HERO_FAILURE,
          payload: error.response && error.response.result.responseMessage 
          ? error.response.result.responseMessage : error.responseMessage
        }) 
      }
    }
    getHero()

    return () => {
      window.removeEventListener('resize', () => responsive())
      mount.current = false
    };
  }, []);
  const navigate = useNavigate();

  return (
    <Box>
      {/* nav */}
      {/* <Navigation /> */}
      {/* End of nav */}
      {
        state.loading ? <ComponentLoader /> : (
          <>
          <Section className='hero' bg={`url(${state.data[0]})`}>
            <Container className='hero'>
              <Glass>
                <Content className='glass'>
                  <h2>Personal Banking</h2>
                  <p>
                  Start saving today and open access to rewarding benefits for the future.
                  </p>
                </Content>
                <a href={'#products'}>
                  <OpenAccount>
                    Open an account
                  </OpenAccount>
                </a>
              </Glass>
            </Container>
          </Section>
            {/* Products */}
            <Section className='products' id='products'>
          <Container>
              {
                mobile 
                ? 
                <>
                <article>
                Our Banking Products and services are tailored to provide you with the best opportunities in Mortgages, Loans and Investments
                  </article>
                <Carousel 
                enableAutoPlay={true} 
                pagination={false}
                // ref={carouselRef}
                isRTL={false}
                autoPlaySpeed={4000}
              //   onNextEnd={() => {
              //     clearTimeout(resetTimeout);
              //     resetTimeout = setTimeout(() => {
              //         carouselRef?.current?.goTo(0);
              //     }, 4000);
              // }}
                >
                  {product.map(({ img, header, content, path }) => (
                  <ProductCard>
                    <Image src={img} alt='products' className='products' />
                    <CardBody>
                      <h2>{header}</h2>
                      <p>{content}</p>
                    </CardBody>
                    <ButtonWrapper>
                      <ButtonLink to={path}>
                        <Button mr='1rem'>Learn More</Button>
                      </ButtonLink>
                    </ButtonWrapper>
                  </ProductCard>
                ))}
                </Carousel>
                </>
               : (
                  <>
                  <article>
                  Start saving today and open up access to rewarding benefits for the future
                  </article>
              <ProductContainer>
                {product.map(({ img, header, content, path }) => (
                  <ProductCard>
                    <Image src={img} alt='products' className='products' />
                    <CardBody>
                      <h2>{header}</h2>
                      <p>{content}</p>
                    </CardBody>
                    <ButtonWrapper>
                      <ButtonLink to={path}>
                        <Button mr='1rem'>Learn More</Button>
                      </ButtonLink>
                    </ButtonWrapper>
                  </ProductCard>
                ))}
              </ProductContainer>
                  </>
                )
              }
          </Container>
            </Section>
            {/* End of Products */}
          {/* Footer */}
          {/* <Footer /> */}
          {/* End of Footer */}
          </>
        )
      }
    </Box>
  );
};

const Box = styled.div`
  width: 100%;
  min-height: 100vh;
  // background-color: #fff;
`;
const Container = styled.div`
  &.hero {
    margin: 0 auto;
    width: 85%;
    ${Screen.miniLargeScreen`
    padding-top: 5rem;
    `}
    ${Screen.mediumpc`
    padding-top: 7rem;
    `}
    ${Screen.mediumPhone`
    width: 100%;
    margin: 0!important;
    padding-top: 4.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    `}
    ${Screen.phone`
    width: 100%;
    margin: 0!important;
    display: flex;
    justify-content: center;
    align-items: center;
    `}
    ${Screen.smallPhone`
    width: 100%;
    margin: 0!important;
    display: flex;
    justify-content: center;
    align-items: center;
    `}
  }
  width: 75%;
  margin: 10rem auto 0;


  ${Screen.miniLargeScreen`
  width: 90%;
  margin: 5rem auto;
  `}
  ${Screen.pad`
  width: 80%;
  margin: 5rem auto;
  `}
  ${Screen.mediumpc`
  margin: 3rem auto;
  `}
  ${Screen.smallpc`
  width: 80%;
  margin: 0 auto!important;
  `}
  ${Screen.tablet`
  width: 90%;
  margin: 0 auto!important;
  `}
  ${Screen.mediumPhone`
  width: 90%;
  margin: 0 auto!important;
  `}
  ${Screen.phone`
  width: 90%;
  margin: 0 auto!important;
  `}
  ${Screen.smallPhone`
  width: 90%;
  margin: 0 auto!important;
  `}


`;
const Image = styled.img`
  margin-bottom: 1rem;
  &.business {
    width: 35rem;
    height: 20rem;
  }
  &.products {
    /* width: 45%; */
    width: 100%;
    height: 200px;
    ${Screen.mediumPhone`
    height: 150px!important
    `}
    ${Screen.phone`
    height: 150px!important
    `}
    ${Screen.smallPhone`
    height: 150px!important
    `}
  }
`;

const Button = styled(NavButton)`
  width: 7rem;
  height: 2rem;
  border-radius: 5px;
  margin: 1.5rem 0;
  margin-right: ${({ mr }) => mr};
  background-color: #002668;
  ${Screen.mediumPhone`
  width: max-content;
  height: 1.5rem;
  padding: 0 .5rem;
  font-size: .8rem;
  margin: 0 0 .5rem;
  `}
  ${Screen.phone`
  width: max-content;
  height: 1.5rem;
  padding: 0 .5rem;
  font-size: .8rem;
  margin: 0 0 .5rem;
  `}
  ${Screen.smallPhone`
  width: max-content;
  height: 1.5rem;
  padding: 0 .5rem;
  font-size: .8rem;
  margin: 0 0 .5rem;
  `}
`;
const OpenAccount = styled(Button)`
  width: 10rem;
  height: 2.5rem;
  border-radius: 5px;
  margin: 3rem 0 0;
  margin-left: 2rem;
  /* margin-right: ${({ mr }) => mr}; */
  background-color: #c81e19;
  box-shadow: 1px 2px 5px #666;
  ${Screen.mediumpc`
  margin: 2rem 0 0 2rem;
  `}
  ${Screen.tablet`
  margin: 2rem 0 0 2rem;
  `}
  ${Screen.surfaceDuo`
  width: max-content;
  height: 2rem;
  padding: .5rem;
  margin: .5rem 0;
  `}
  ${Screen.mediumPhone`
  width: max-content;
  height: 2rem;
  padding: .5rem;
  margin: .5rem 0;
  `}
  ${Screen.phone`
  width: max-content;
  height: 2rem;
  padding: .5rem;
  margin: .5rem 0;
  `}
  ${Screen.smallPhone`
  width: max-content;
  height: 2rem;
  padding: .5rem;
  margin: .5rem 0;
  `}
`;

const Section = styled.section`
  &.hero {
    width: 100%;
    min-height: 100vh;
    /* background-image: url(${PersonalImg1}); */
    background-image: ${({ bg }) => bg};
    background-position: center;
    background-size: cover;
    margin-top: 80px;
    padding-top: 12rem;

    ${Screen.tablet`
    min-height: 50vh!important;
    `}
    ${Screen.mediumpc`
    min-height: 50vh!important;
    `}
    ${Screen.smallpc`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 1rem;
    `}
     ${Screen.tablet`
    min-height: 40vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
     ${Screen.surfaceDuo`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.mediumPhone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.phone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.smallPhone`
    min-height: 30vh;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
  }
  &.products {
    margin-top: 3rem;
    ${Screen.phone`
    margin-top: 0;
    `}
    ${Screen.smallPhone`
    margin-top: 1rem!important;
    `}

    p {
      text-align: center;
      margin-top: 1rem;
      font-size: 1.2rem;
    }

    article {
      font-style: italic;
      text-align: center;
      font-size: 1.2rem;
      font-weight: 400;
      ${Screen.surfaceDuo`
      font-size: 1rem;
      padding-top: 1rem
      `}
      ${Screen.mediumPhone`
      font-size: .9rem;
      padding-top: 1rem
      `}
      ${Screen.phone`
      font-size: .9rem;
      padding-top: 1rem
      `}
      ${Screen.smallPhone`
      font-size: .9rem;
      padding-top: 1rem
      `}
    }
  }
`;

const ProductContainer = styled.div`
  margin-top: 3rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  ${Screen.pad`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5px;
  `}
`;
const ButtonLink = styled(Link)`
  text-decoration: none;
  color: #fff;
`;

const Glass = styled.div`
  width: 540px;
  height: 270px;
  background: rgba(217, 217, 217, 0.5);

  ${Screen.mediumpc`
  height: 230px;
  `}
  ${Screen.tablet`
  height: 230px;
  `}
  ${Screen.surfaceDuo`
  width: 90%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  `}
  ${Screen.mediumPhone`
  width: 90%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  `}
  ${Screen.phone`
  width: 90%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  `}
  ${Screen.smallPhone`
  width: 90%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  `}
`;

const Content = styled.div`
  padding-left: 2rem;
  ${Screen.phone`
  padding-left: 0;
  `}
  ${Screen.smallPhone`
  padding-left: 0;
  `}
  &.glass {
    h2 {
      padding-top: 3rem;
      font-size: 45px;
      color: #002668;
      ${Screen.mediumpc`
      font-size: 30px;
      `}
      ${Screen.tablet`
      font-size: 30px;
      `}
      ${Screen.surfaceDuo`
      padding-top: 2rem;
      font-size: 28px;
      text-align: center;
      `}
      ${Screen.mediumPhone`
      padding-top: 2rem;
      font-size: 28px;
      text-align: center;
      `}
      ${Screen.phone`
      padding-top: 2rem;
      font-size: 24px;
      text-align: center;
      `}
      ${Screen.smallPhone`
      padding-top: 2rem;
      font-size: 24px;
      text-align: center;
      `}
    }

    p {
      color: #fff;
      padding-top: 0.5rem;
      text-align: left;
      font-size: 18px;
      text-shadow: 3px 3px 3px #666;
      ${Screen.mediumpc`
      color: #002668;
      text-shadow: none;
      `}
      ${Screen.smallpc`
      color: #002668;
      text-shadow: none;
      font-size: 16px;
      `}
      ${Screen.tablet`
      color: #002668;
      text-shadow: none;
      `}
      ${Screen.surfaceDuo`
      font-size: 16px;
      text-align: center;
      text-shadow: none;
      color: #002668 ;
      padding: .5rem;
      `}
      ${Screen.mediumPhone`
      font-size: 16px;
      text-align: center;
      text-shadow: none;
      color: #002668 ;
      padding: .5rem;
      `}
      ${Screen.phone`
      font-size: 14px;
      text-align: center;
      text-shadow: none;
      color: #002668 ;
      padding: .5rem;
      `}
      ${Screen.smallPhone`
      font-size: 14px;
      text-align: center;
      text-shadow: none;
      color: #002668 ;
      padding: .5rem;
      `}
    }
  }
`;

const ProductCard = styled.div`
  width: 340px;
  height: auto;
  background-color: #fff;
  box-shadow: 5px 3px 5px #ccc, -5px 3px 5px #ccc;
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 3rem;

  ${Screen.mediumpc`
  width: 300px!important;
  box-shadow: 3px 1px 3px #ccc, -3px 1px 3px #ccc;
  `}
  ${Screen.smallpc`
  width: 300px!important;
  box-shadow: 3px 1px 3px #ccc, -3px 1px 3px #ccc;
  `}
   ${Screen.tablet`
  width: 300px!important;
  box-shadow: 3px 1px 3px #ccc, -3px 1px 3px #ccc;
  `}
   ${Screen.surfaceDuo`
  width: 300px!important;
  box-shadow: 3px 1px 3px #ccc, -3px 1px 3px #ccc;
  `}
  ${Screen.mediumPhone`
  width: 250px!important;
  box-shadow: 3px 1px 3px #ccc, -3px 1px 3px #ccc;
  `}
  ${Screen.phone`
  width: 230px!important;
  box-shadow: 3px 1px 3px #ccc, -3px 1px 3px #ccc;
  `}
  ${Screen.smallPhone`
  width: 210px!important;
  box-shadow: 3px 1px 3px #ccc, -3px 1px 3px #ccc;
  `}
`;
const CardBody = styled.div`
  padding: 0 1rem;
  /* padding-top: 2rem; */
  h2 {
    font-size: 20px;
    color: #c81e19;
    padding-top: 1rem;
    ${Screen.surfaceDuo`
    font-size: 18px;
    padding-top: 0 0 0.5rem;
    `}
    ${Screen.mediumPhone`
    font-size: 18px;
    padding-top: 0 0 0.5rem;
    `}
    ${Screen.phone`
    font-size: 17px;
    padding-top: 0 0 0.5rem;
    `}
    ${Screen.smallPhone`
    font-size: 16px;
    padding-top: 0 0 0.5rem;
    `}
  }

  p {
    font-size: 15px !important;
    text-align: left !important;
    color: #002668;
    ${Screen.surfaceDuo`
    font-size: 14px!important;
    `}
    ${Screen.mediumPhone`
    font-size: 14px!important;
    `}
    ${Screen.phone`
    font-size: 13px!important;
    `}
    ${Screen.smallPhone`
    font-size: 12px!important;
    `}
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  margin-left: 1rem;
  margin-top: 1rem;
  ${Screen.surfaceDuo`
  margin-bottom: .5rem!important
  `}
  ${Screen.phone`
  margin-bottom: .5rem!important
  `}
  ${Screen.smallPhone`
  margin-bottom: .5rem!important
  `}
`;

export default Personal;
