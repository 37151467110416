import {
  BoardMinus,
  BoardPlus,
  Card2,
  Check,
  // Dipo,
  Director,
  // MaziImg,
  Onism,
  Prepaid,
  Reflect,
} from '../../../assets/img';

export const abbeySave = {
  // features: [
  //   {
  //     img: Check,
  //     item: 'No ID required',
  //   },
  //   {
  //     img: Check,
  //     item: '+Zero opening balance allowed',
  //   },
  //   {
  //     img: Check,
  //     item: 'Issuance of Debit Card',
  //   },
  //   {
  //     img: Check,
  //     item: '24 Hour Banking available via E-banking service',
  //   },
  //   {
  //     img: Check,
  //     item: 'Easy to open and operate',
  //   },
  //   {
  //     img: Check,
  //     item: 'Minimal requirements needed to open',
  //   },
  //   {
  //     img: Check,
  //     item: 'Transaction limits makes it low-value account for the bank',
  //   },
  // ],
  benefits: [
    {
      img: Check,
      item: 'No minimum balance required',
    },
    {
      img: Check,
      item: 'Competitive interest rates',
    },
    {
      img: Check,
      item: 'Access to mobile and internet banking',
    },
    {
      img: Check,
      item: 'ATM card issuance available',
    }
  ],
  requirements: [
    {
      img: Check,
      item: 'Passport photograph',
    },
    {
      img: Check,
      item: 'BVN/NIN',
    },
    {
      img: Check,
      item: 'Zero opening balance',
    },
    {
      img: Check,
      item: 'Filled account opening form'
    }
  ],
}


export const abbeyPlus = {
  // features: [
  //   {
  //     img: Check,
  //     item: 'Any form of ID is acceptable',
  //   },
  //   {
  //     img: Check,
  //     item: 'Zero minimum opening balance allowed',
  //   },
  //   {
  //     img: Check,
  //     item: 'Maximum single deposit of N100,000',
  //   },
  //   {
  //     img: Check,
  //     item: 'Maximum cumulative balance of N500,000',
  //   },
  //   {
  //     img: Check,
  //     item: 'Comes with a Debit card or convenient banking',
  //   },
  //   {
  //     img: Check,
  //     item: '24 Hour Banking available via E-Banking service',
  //   },
  //   {
  //     img: Check,
  //     item: 'Easy to open and operate',
  //   },
  // ],
  benefits: [
    {
      img: Check,
      item: 'Higher interest rates than standard savings accounts',
    },
    {
      img: Check,
      item: 'No monthly maintenance fees',
    },
    {
      img: Check,
      item: 'ATM card and online banking access',
    }
  ],
  requirements: [
    {
      img: Check,
      item: 'Passport photograph',
    },
    {
      img: Check,
      item: 'BVN & NIN',
    },
    {
      img: Check,
      item: "Any valid form of identification (e.g., NIN slip, Voter's card, Driver’s license, International passport)",
    },
    {
      img: Check,
      item: "Zero opening balance",
    },
    {
      img: Check,
      item: 'Filled account opening form',
    }
  ],
}

export const abbeyZoomers = {
  // features: [
  //   {
  //     img: Check,
  //     item: 'Zero opening balance',
  //   },
  //   {
  //     img: Check,
  //     item: 'Zero Minimum operating balance',
  //   },
  //   {
  //     img: Check,
  //     item: 'Issuance of a Free card (WALLET-type Prepaid card recommended)',
  //   },
  // ],
  benefits: [
    {
      img: Check,
      item: 'Zero maintenance fees',
    },
    {
      img: Check,
      item: 'Access to mobile and internet banking',
    },
    {
      img: Check,
      item: 'Debit card issuance',
    }
  ],
  requirements: [
    {
      img: Check,
      item: '1 Passport photograph',
    },
    {
      img: Check,
      item: 'School ID card (for students)',
    },
    {
      img: Check,
      item: 'BVN',
    },
    {
      img: Check,
      item: 'To be opened via Abbey mobile app',
    }
  ]
} 

export const bloomTeens = {
  // features: [
  //   {
  //     img: Check,
  //     item: 'Open account with as low as N5,000.00',
  //   },
  //   {
  //     img: Check,
  //     item: `Regular payments to your account can be set up 
  //         by parents or guardians.`,
  //   },
  //   {
  //     img: Check,
  //     item: 'Get a card/wallet for convenient banking.',
  //   },
  // ],
  benefits: [
    {
      img: Check,
      item: 'Competitive interest rates',
    },
    {
      img: Check,
      item: `Debit card issuance for responsible spending`,
    },
    {
      img: Check,
      item: 'Parental monitoring features',
    },
  ],
  requirements: [
    {
      img: Check,
      item: '1 Passport photograph (teenager & parent/guardian)',
    },
    {
      img: Check,
      item: `Teenager's birth certificate`,
    },
    {
      img: Check,
      item: `Parent/guardian's valid means of ID`,
    },
    {
      img: Check,
      item: `Parent/guardian's BVN / NIN`,
    },
    {
      img: Check,
      item: `Recent utility bill of residence`,
    },
    {
      img: Check,
      item: `Fully completed KYC form`,
    },
  ]
} 

export const fixedFeatures = [
  {
    img: Check,
    item: 'Account opening requirements for individual and corporate',
  },
  {
    img: Check,
    item: 'Retail account not mandatory',
  },
  {
    img: Check,
    item: 'No legal search required.',
  },
  {
    img: Check,
    item: 'Investment instruction must be collected from customer.',
  },
  {
    img: Check,
    item: `The interest rate is 
        fixed for the full period of your investment unless terminated 
        before period of maturity.`,
  },
  {
    img: Check,
    item: 'Penalty for premature liquidation',
  },
];

export const bloomKidz = {
  // features: [
  //   {
  //     img: Check,
  //     item: `To be opened in the child's name`,
  //   },
  //   {
  //     img: Check,
  //     item: `Interest reverts to regular 
  //         savings interest rate after four withdrawals per annum`,
  //   },
  //   {
  //     img: Check,
  //     item: `Minimum 
  //         opening balance of N1,000.00`,
  //   },
  //   {
  //     img: Check,
  //     item: `Standing Order to be set up from 
  //         Parent's account`,
  //   },
  //   {
  //     img: Check,
  //     item: `Non-Issuance of debit card`,
  //   },
  // ],
  benefits: [
    {
      img: Check,
      item: 'Attractive interest rates',
    },
    {
      img: Check,
      item: 'Parental control over transactions',
    },
    {
      img: Check,
      item: 'Free financial literacy resources for kids',
    }
  ],
  requirements: [
    {
      img: Check,
      item: `1 Passport photograph (child & parent/guardian)`,
    },
    {
      img: Check,
      item: `Child's birth certificate`,
    },
    {
      img: Check,
      item: `Parent/guardian's valid means of ID`,
    },
    {
      img: Check,
      item: `Parent/guardian's BVN & NIN`,
    },
    {
      img: Check,
      item: `Recent utility bill of residence`,
    },
    {
      img: Check,
      item: `Fully completed KYC form`,
    }
  ]
}

export const currentAccount = {
  // features: [
  //   {
  //     img: Check,
  //     item: `Zero minimum opening and operating balance allowed`,
  //   },
  //   {
  //     img: Check,
  //     item: `Cheque book 
  //         will be issued based on request.`,
  //   },
  //   {
  //     img: Check,
  //     item: `No maximum period of holding 
  //         (Continuing Nature)`,
  //   },
  // ],
  benefits: [
    {
      img: Check,
      item: `No transaction limits`,
    },
    {
      img: Check,
      item: `Access to overdrafts and loans`,
    },
    {
      img: Check,
      item: `Cheque book issuance`,
    },
    {
      img: Check,
      item: `Online banking and mobile app access`,
    },
  ],
  requirements: [
    {
      img: Check,
      item: `1 Passport photograph`,
    },
    {
      img: Check,
      item: `BVN & NIN`,
    },
    {
      img: Check,
      item: `Valid means of identification (NIN, Voter's card, Driver's license, International passport)`,
    },
    {
      img: Check,
      item: `Recent utility bill (not older than 3 months)`,
    },
    {
      img: Check,
      item: `Two (2) duly completed and approved reference forms`,
    },
    {
      img: Check,
      item: `Fully completed KYC form`,
    },
  ],
} 

export const savingsAccount = {
  // features: [
  //   {
  //     img: Check,
  //     item: 'Money can be withdrawn using withdrawal slip.',
  //   },
  //   {
  //     img: Check,
  //     item: `Accepts lodgment of cheques and dividend 
  //         warrants (2 satisfactory references required for lodgments above N2m)`,
  //   },
  //   {
  //     img: Check,
  //     item: 'Reasonable interest payable',
  //   },
  //   {
  //     img: Check,
  //     item: `Forfeiture of interest after four (4) 
  //         debit withdrawals`,
  //   },
  //   {
  //     img: Check,
  //     item: 'Joint account mandate permissible',
  //   },
  //   {
  //     img: Check,
  //     item: `No maximum 
  //         period of holding (Continuing Nature)`,
  //   },
  //   {
  //     img: Check,
  //     item: `No loan facility is provided 
  //         against savings account.`,
  //   },
  //   {
  //     img: Check,
  //     item: 'Zero minimum opening balance allowed',
  //   },
  //   {
  //     img: Check,
  //     item: 'Issuance of Debit Card',
  //   },
  //   {
  //     img: Check,
  //     item: '24 Hour Banking available via E-Banking services',
  //   },
  // ],
  benefits: [
    {
      img: Check,
      item: 'Competitive interest rates',
    },
    {
      img: Check,
      item: 'ATM and online banking access',
    },
    {
      img: Check,
      item: 'Convenient deposits and withdrawals',
    },
  ],
  requirements: [
    {
      img: Check,
      item: 'Passport photograph',
    },
    {
      img: Check,
      item: 'BVN & NIN',
    },
    {
      img: Check,
      item: "Valid means of identification (NIN, Voter's card, Driver's license, International passport)",
    },
    {
      img: Check,
      item: 'Recent utility bill (not older than 3 months)',
    },
    {
      img: Check,
      item: 'Fully completed KYC form',
    }
  ]
}

export const abbeyBiz = {
  // features: [
  //   {
  //     img: Check,
  //     item: `Minimum opening balance 
  //     of N20,000 and Minimum operating 
  //     balance of N2000`,
  //   },
  //   {
  //     img: Check,
  //     item: `Cheque book will be issued  based on request`,
  //   },
  //   {
  //     img: Check,
  //     item: `No maximum period of holding (Continuing Nature)`,
  //   },
  // ],
  benefits: [
    {
      img: Check,
      item: `Flexible transaction limits`,
    },
    {
      img: Check,
      item: `Business advisory services`,
    },
    {
      img: Check,
      item: `Access to business loans and overdrafts`,
    },
    {
      img: Check,
      item: `Free bulk payment services`,
    }
  ],
  requirements: [
    {
      img: Check,
      item: `1 Passport photograph of all signatories/directors`,
    },
    {
      img: Check,
      item: `BVN & NIN of all the signatories`,
    },
    {
      img: Check,
      item: `Two (2) duly completed and approved reference forms`,
    },
    {
      img: Check,
      item: `Copy of CAC Certificate of Registration/Incorporation`,
    },
    {
      img: Check,
      item: `Copy of Memorandum and Articles of Association`,
    },
    {
      img: Check,
      item: `Tax Identification Number (TIN)`,
    },
    {
      img: Check,
      item: `Form CO2 (Allotment of Shares - Certified by CAC)`,
    },
    {
      img: Check,
      item: `Form CO7 (Particulars of Directors - Certified by CAC)`,
    },
    {
      img: Check,
      item: `Proof of company address (Recent utility bill)`,
    },
    {
      img: Check,
      item: `Evidence of Registration with SCUML (if applicable)`,
    },
    {
      img: Check,
      item: `Funding of account for Corporate/legal search report`,
    },
  ],
  government: [
    {
      img: Check,
      item: `Minimum opening balance of ₦20,000`,
    },
    {
      img: Check,
      item: `One Passport photograph of all signatories/officers`,
    },
    {
      img: Check,
      item: `BVN & NIN of all the signatories`,
    },
    {
      img: Check,
      item: `Proof of Identity for all signatories/officers`,
    },
    {
      img: Check,
      item: `Proof of office address (Recent utility bill)`,
    },
    {
      img: Check,
      item: `Evidence of Registration with Nigerian Investment Promotion Council (NIPC)`,
    },
    {
      img: Check,
      item: `Letter of authority to open the account (from the Auditor General State /Federal)`,
    },
  ]
} 

export const bizExtra = {
  // features: [
  //   {
  //     img: Check,
  //     item: `Minimum opening balance of N20,000 and Minimum operating balance of N2,000`,
  //   },
  //   {
  //     img: Check,
  //     item: `Non-clearing Cheque book will be issued based on request.`,
  //   },
  //   {
  //     img: Check,
  //     item: `No maximum period of holding (Continuing Nature)`,
  //   },
  // ],
  benefits: [
    {
      img: Check,
      item: `Higher transaction limits`,
    },
    {
      img: Check,
      item: `Custom business financial solutions`,
    },
    {
      img: Check,
      item: `Preferential loan rates`,
    },
    {
      img: Check,
      item: `Dedicated relationship manager`,
    },
  ],
  requirements: [
    {
      img: Check,
      item: `1 Passport photograph of all signatories/directors`,
    },
    {
      img: Check,
      item: `Copy of CAC Certificate of Registration/Incorporation`,
    },
    {
      img: Check,
      item: `Copy of Memorandum and Articles of Association`,
    },
    {
      img: Check,
      item: `Tax Identification Number (TIN)`,
    },
    {
      img: Check,
      item: `BVN & NIN`,
    },
    {
      img: Check,
      item: `Form CO2 (Allotment of Shares - Certified by CAC)`,
    },
    {
      img: Check,
      item: `Form CO7 (Particulars of Directors - Certified by CAC)`,
    },
    {
      img: Check,
      item: `Proof of company address (Recent utility bill)`,
    },
    {
      img: Check,
      item: `Evidence of Registration with SCUML (if applicable)`,
    },
    {
      img: Check,
      item: `Funding of account for Corporate/legal search report`,
    }
  ],
  government: [
    {
      img: Check,
      item: `Minimum opening balance of ₦20,000`,
    },
    {
      img: Check,
      item: `One Passport photograph of all signatories/officers`
    },
    {
      img: Check,
      item: `BVN & NIN`,
    },
    {
      img: Check,
      item: `Proof of Identity for all signatories/officers`,
    },
    {
      img: Check,
      item: `Proof of office address (Recent utility bill)`,
    },
    {
      img: Check,
      item: `Letter of authority to open the account (Auditor General of State or Federal)`,
    },
  ]
}

export const mortgageItems = [
  {
    icon: Check,
    item: 'Equity Contribution: 30% equity required ',
  },
  {
    icon: Check,
    item: 'Tenor: Up to 10 years',
  },
  {
    icon: Check,
    item: 'Joint spouse borrowing allowed for Individuals',
  },
  {
    icon: Check,
    item: 'Perfectible title document',
  },
];

export const equityItems = [
  {
    icon: Check,
    item: 'Tenor: Up to 5 years',
  },
  {
    icon: Check,
    item: "Loan utilization at customer's discretion",
  },
  {
    icon: Check,
    item: 'Perfectible title document',
  },
];

export const refinancingItems = [
  {
    icon: Check,
    item: 'No equity contribution required',
  },
  {
    icon: Check,
    item: "Tenor: Up to 7 years",
  },
  {
    icon: Check,
    item: 'Perfectible Title',
  },
];

export const estateItems = [
  {
    icon: Check,
    item: 'Equity: 30% equity contribution',
  },
  {
    icon: Check,
    item: 'Tenor: maximum tenor of 3 years',
  },
  {
    icon: Check,
    item: 'Perfectible Title',
  },
  {
    icon: Check,
    item: 'Approvals from the applicable authorities must be obtained',
  },
];

export const cardItems = [
  {
    img: Card2,
    header: 'Debit Cards',
    content: `Abbey Mortgage Bank Debit Cards ensure 24/7 access 
    to your account anywhere and whenever in Nigeria. Make daily 
    purchases and pay your bills without carrying cash. It's far 
    more convenient and safer than cash.`,
  },
  {
    img: Reflect,
    header: 'Reflect Yourself Cards',
    content: `You have the option to give your card a distinctive 
    appearance and feel with the Abbey Reflect Yourself Card. 
    Showcase your family, a pet, or your favourite picture and 
    have the picture printed on your debit or prepaid cards.`,
  },
  {
    img: Prepaid,
    header: 'Prepaid Cards',
    content: `Get a Prepaid Card and get better at your financial 
    management. Top up money, pay bills and manage your spending 
    wisely.`,
  },
];

export const corporateItems = [
  {
    content: 'Abbey Code of Corporate Governance',
    name: 'Code_of_Corporate_Governance.pdf'
  },
  {
    content: 'Members of Board Committees',
    path: '/about/board-committees'
  },
  {
    content: `Directors' Profiles`,
    path: '/about/board'
  },
  {
    content: 'Registrars’ Details',
    path: '#'
  },
];

export const egmItems = [
  {
    name: 'Notice of Meeting 2023',
    path: 'Notice_Of_Meeting.pdf'
  },
  {
    name: 'Notice of EGM 2025',
    path: 'notice_of_egm_2025.pdf'
  },
  {
    name: 'Abbey Bank Draft Memart',
    path: 'abbey_bank_draft_2025.pdf'
  },
];

export const eventItems = [
  {
    item: 'Annual General Meeting',
    path: '/agm'
  },
  {
    item: 'Extraordinary General Meeting',
    path: '/egm'
  }
];

export const formItems = [
  {
    content: 'E-dividend Mandate Form',
    name: 'e-dividend_mandate_form.pdf'
  },
  {
    content: 'Share-Portal Application Form',
    name: 'Share_Portal_Applicatication_Form.pdf'
  },
  {
    content: 'Full Dematerialization Form for Immigration',
    name: 'Full_Dematerialization_Form_for_Migration.pdf'
  },
  {
    content: 'Proxy Form',
    name: 'Proxy_Form.pdf'
  },
  {
    content: 'Update Form',
    name: 'Update_Form.pdf'
  },
];

export const breadcrumbMenu = [
  {
    name: 'About Us',
    path: '/about',
  },
  {
    name: 'Leadership and Governance',
    path: '/about/leadership-and-governance',
  },
  {
    name: 'Governance Structure',
    path: '/about/governance-structure',
  },
];
export const boardBreadcrumb = [
  {
    name: 'About Us',
    path: '/about',
  },
  {
    name: 'Leadership and Governance',
    path: '/about/leadership-and-governance',
  },
  {
    name: 'Board of Directors',
    path: '/about/board',
  },
];
export const managementBreadcrumb = [
  {
    name: 'About Us',
    path: '/about',
  },
  {
    name: 'Leadership and Governance',
    path: '/about/leadership-and-governance',
  },
  {
    name: 'Senior Management',
    path: '/about/senior-management',
  },
];
export const profileBreadcrumb = [
  {
    name: 'About Us',
    path: '/about',
  },
  {
    name: 'Leadership and Governance',
    path: '/about/leadership-and-governance',
  },
  {
    name: 'Board of Directors',
    path: '/about/board',
  },
  {
    name: 'Senior Management',
    path: '/about/senior-management',
  },
];

export const leadership = [
  {
    img: Onism,
    caption: 'Board of Directors',
    path: '/about/board',
  },
  {
    img: Director,
    caption: 'Senior Management',
    path: '/about/senior-management',
  },
];

export const governance = [
  {
    title: 'Board Committee',
    content: `The Board has appointed a number of 
    committees consisting of certain Directors and 
    co-opted non-director members.`,
    path: '/about/board-committees',
  },
  {
    title: 'Board Responsibilities',
    content: `The Board aims to promote Abbey’s 
    long-term success, deliver sustainable value 
    to shareholders and promote a culture of 
    openness and debate.`,
    path: '/about/board-responsibilities',
  },
  {
    title: 'Governance Structure',
    content: `Find out more about the governance 
    structures and processes we use to meet our 
    responsibilities to stakeholders and protect 
    the business.`,
    path: '/about/governance-structure',
  },
];

export const committee = [
  {
    name: 'About Us',
    path: '/about',
  },
  {
    name: 'Leadership and Governance',
    path: '/about/leadership-and-governance',
  },
  {
    name: 'Board Committees',
    path: '/about/board-committees',
  },
];

export const auditItems = [
  {
    open: BoardPlus,
    close: BoardMinus,
    name: 'Audit and Compliance Committee',
    members: [
      {
        name: 'Members',
      },
      {
        name: 'High Chief Oni (Chairman)',
      },
      {
        name: 'Prof. M. Umego',
      },
      {
        name: 'Brig. Gen. J. Obasa',
      },
    ],
    duties: [
      {
        item: 'The Audit and Compliance Committee is responsible for:',
      },
      {
        item: 'Monitoring the integrity of financial statements',
      },
      {
        item: `Overseeing the internal controls systems relating to 
        financial reporting`,
      },
      {
        item: `Monitoring and reviewing the effectiveness of the 
        Global Internal Audit function`,
      },
      {
        item: `Reviewing the Company’s financial and accounting 
        policies and practices`,
      },
      {
        item: `Oversight and remuneration of the external auditor and making 
        recommendations to the Board on the appointment of the external 
        auditor`,
      },
    ],
  },
];

export const strategyItems = [
  {
    open: BoardPlus,
    close: BoardMinus,
    name: 'Strategy and Financial Analysis',
    members: [
      {
        name: 'Members',
      },
      {
        name: 'Mr. N. Okpala - Chairman',
      },
      {
        name: 'Mr. M. Adewumi',
      },
      {
        name: 'Mr. D. Adeoye',
      },
    ],
    duties: [
      {
        item: 'The Strategy and Financial Analysis Committee is responsible for:',
      },
      {
        item: 'Analysis of financial statements',
      },
      {
        item: `Reviewing the Bank’s financial and 
        accounting policies and practices`,
      },
    ],
  },
];
export const creditItems = [
  {
    open: BoardPlus,
    close: BoardMinus,
    name: 'Credit and Risk Management',
    members: [
      {
        name: 'Members',
      },
      {
        name: 'High Chief S. Oni',
      },
      {
        name: 'Mr. N. Okpala',
      },
      {
        name: 'Mr. M. Adewumi',
      },
      {
        name: 'Mr. Dipo Adeoye',
      },
    ],
    duties: [
      {
        item: 'The Credit and Risk Management Committee is responsible for:',
      },
      {
        item: `Advising the Board on high-level risk-related matters and risk governance, including current and 
        forward looking risk exposures, future risk strategy and management of 
        risk within the Bank;`,
      },
      {
        item: `Advising the Board on risk appetite and risk tolerance;`,
      },
      {
        item: `Reviewing the effectiveness of the Bank’s risk 
        management framework`,
      },
      {
        item: `Monitoring executive control and management of risk 
        including top and emerging risks;`,
      },
      {
        item: `Oversight of matters relating to the bank’s 
        information security environment and cyber security 
        framework`,
      },
    ],
  },
];

export const governanceItems = [
  {
    open: BoardPlus,
    close: BoardMinus,
    name: 'Governance and Remuneration',
    members: [
      {
        name: 'Members',
      },
      {
        name: 'Prof. M. Umego',
      },
      {
        name: 'Brig. Gen. J. Obasa',
      },
    ],
    duties: [
      {
        item: 'The Governance and Remuneration Committee is responsible for:',
      },
      {
        item: `Making recommendations to the Board, for approval by shareholders, on 
        the remuneration policy of the Bank;`,
      },
      {
        item: `Setting the over-arching principles, parameters and governance framework of 
        the bank’s remuneration policy;`,
      },
      {
        item: `Approving the remuneration of executive Directors and other senior employees;`,
      },
      {
        item: `Regularly reviewing the effectiveness of 
        the remuneration policy of the Company and its subsidiaries in the 
        context of consistent and effective risk management.`,
      },
    ],
  },
];

export const statutoryItems = [
  {
    open: BoardPlus,
    close: BoardMinus,
    name: 'Statutory Audit Committee',
    members: [
      {
        name: 'Members',
      },
      {
        name: 'Mr. Adekunle Alli - Chairman',
      },
      {
        name: 'Mr. Gbadebo Ajeigbe',
      },
      {
        name: 'High Chief Oni',
      },
      {
        name: 'Price M.O.T Tobun',
      },
    ],
  },
];

export const principles = [
  {
    item: 'The Board shall understand and fully appreciate the credit risk issues and key performance indicators affecting the ability of the Bank to achieve its business objectives;',
  },
  {
    item: 'The credit risks and key performance indicators shall be benchmarked against industry norms and practice, so that the Bank’s performance can be evaluated;',
  },
  {
    item: 'Directors and Executive Management shall be trained to enable them understand the Bank’s business focus as well as accompanying credit risks, nature of the risks being managed and an appreciation of the techniques of managing the risk effectively;',
  },
  {
    item: 'The Bank’s credit risk management systems shall be subject to periodic review and the results shall be reported to the Board;',
  },
  {
    item: 'The Board desires to maintain a reasonable ration on Non-Performing Loan (NPL)/Total Loan Portfolio at all times, that will not impact negatively on the company’s profitability after tax. At all times NPLs should be lower than statutory maximum of 30%.',
  },
  {
    item: 'Review and approve the establishment of a risk management function that would independently identify, measure, monitor and control risks inherent in all risk-taking units of the Bank',
  },
  {
    item: 'Approve and periodically review The Bank’s risk strategy and policies;',
  },
  {
    item: 'Approve The Bank’s risk appetite and monitor its risk profile against this appetite;',
  },
  {
    item: 'Ensure that the management of The Bank implements effective risk management processes to identify risks, measure potential impact and proactively manage these risks;',
  },
  {
    item: 'Ensure that the Bank maintains a sound system of risk management and internal control to ensure:',
    subItems: [
      {
        item: 'Efficiency and effectiveness of operations;',
      },
      {
        item: 'Safeguarding the Bank assets (including information);',
      },
      {
        item: 'Compliance with applicable laws, regulations and supervisory requirements;',
      },
      {
        item: 'Reliability of reporting; and',
      },
      {
        item: 'Behaving responsibly towards all stakeholders.',
      },
    ],
  },
  {
    item: 'Ensure risk strategy reflects the Company’s tolerance for risk; and',
  },
  {
    item: 'Periodically receive risk reports from management highlighting key risk areas, control failures and remedial action steps taken by management.',
  },
];

export const scope = [
  {
    item: 'Facilitate the effective management of credit risk by The Bank;',
  },
  {
    item: 'Approve credit risk management policies, underwriting guidelines and standard proposals on the recommendation of the Credit Management Committee;',
  },
  {
    item: 'Approve definition of risk and return preferences and target risk portfolio;',
  },
  {
    item: 'Approve the Bank’s credit rating methodology and ensure effective implementation;',
  },
  {
    item: 'Approve credit risk appetite and portfolio strategy;',
  },
  {
    item: 'Approve new credit products and processes;',
  },
  {
    item: 'Approve assignment of credit approval authority on the recommendation of Risk Management Committee',
  },
  {
    item: `Approve changes to credit policy guidelines on the recommendation of
    Risk Management Committee;`,
  },
  {
    item: `Approve credit facility requests and proposals within limits 
    defined by the Bank’s credit policy and within the statutory 
    requirements set by the regulatory/ supervisory authorities;`,
  },
  {
    item: 'Recommend credit facility requests above stipulated limit to the Board;',
  },
  {
    item: 'Review credit risk reports on a periodic basis;',
  },
  {
    item: 'Approve credit exceptions in line with Board approval; and',
  },
  {
    item: 'Make recommendations to the Board on credit policy and strategy where appropriate.',
  },
  {
    item: `Oversee the establishment of a formal risk management 
    framework for The Bank. The framework shall define risks and risk 
    limits that are acceptable and unacceptable to the Bank. It shall 
    provide guidelines and standards to administer the acceptance and 
    on-going management of all risks;`,
  },
  {
    item: `Ensure that adequate policies are in place to manage and 
    mitigate the adverse effects of both business and control risks in 
    our operations; Ensure compliance with established policy through 
    periodic review of reports provided by management, statutory 
    auditors and the supervisory authorities;`,
  },
  {
    item: 'Approve the appointment of qualified officers to manage the risk function;',
  },
  {
    item: 'Oversee the management of all risks in The Bank;',
  },
  {
    item: `Re-evaluate the risk management framework of the Bank on a 
    periodic basis to accommodate changes in internal or external 
    factors;`,
  },
  {
    item: `Evaluate internal processes for identifying, assessing, 
    monitoring and managing key risk areas, particularly:`,
    subItems: [
      {
        item: 'important judgments and accounting estimates;',
      },
      {
        item: 'business and operational risks in the areas of credit, market and operations;',
      },
      {
        item: 'specific risks relating to outsourcing; and',
      },
      {
        item: 'consideration of environmental, community and social risks.',
      },
    ],
  },
  {
    item: `Evaluate along with management the adequacy of the risk 
    management systems and control environment in The Bank;`,
  },
  {
    item: `Evaluate The Bank’s risk profile, the action plans in place 
    to manage risks, and monitor progress against plan to achieve these 
    actions;`,
  },
  {
    item: `Review and approve The Bank’s policy regarding press 
    releases as well as financial information provided to analysts 
    and rating agencies;`,
  },
  {
    item: `Review the processes The Bank has in place for assessing 
    and continuously improving internal controls, particularly those 
    related to areas of significant risk; and`,
  },
  {
    item: `Approve the provision of risk management services by 
    external providers.`,
  },
];

export const composition = [
  {
    item: `It shall consist of such number of persons as the Board may 
    decide, provided that a Non-Executive member of the Board shall 
    act as Chairman of the Committee.`,
  },
  {
    item: `Members must possess business knowledge/skills and 
    familiarity with Banking and finance, credit risk management, 
    Banking operations, risk management, accounting practices and 
    concepts;`,
  },
  {
    item: `The company’s Head of Credit and Risk Management will sit in 
    attendance at meetings of the Committee.`,
  },
];

export const auditPrinciples = [
  {
    item: `Hold regular meetings at least four times per 
    year and report to the Board on a regular basis. 
    Meetings shall include any participants the Committee 
    deems appropriate, shall be of sufficient duration, and 
    scheduled at such times, as the Committee deems 
    appropriate to discharge properly its responsibilities.`,
  },
  {
    item: `Meet as deemed necessary and appropriate, with 
    the Head of Internal Audit, Head of Compliance and other 
    employees of the Company.`,
  },
  {
    item: `Form and delegate to one or more subcommittees 
    all or any portion of the Committee’s authority, 
    duties and responsibilities, and may establish such 
    rules as it determines necessary or appropriate to 
    conduct the Committee’s business.`,
  },
  {
    item: `Have direct access to, and complete and open 
    communication with, the Company’s management, including 
    the Head of Internal Audit, Head of Compliance and other
    employees of any department of the Bank, and may obtain 
    advice and assistance from internal or external sources 
    on legal, risk or other advisors.`,
  },
  {
    item: `Review and assess annually its performance and 
    report the results to the Board. The Committee shall 
    review and assess annually the adequacy of this charter 
    and, if appropriate, recommend changes to the charter to 
    the Board.`,
  },
];

export const objectiveOne = [
  {
    item: `Identify and evaluate the compliance risks 
    (as defined in paragraph 1.2) within the organisation;`,
  },
  {
    item: `Organise, coordinate and structure 
    compliance-related decentralised controls;`,
  },
  {
    item: `Control and monitor all measures taken to 
    mitigate against compliance risks;`,
  },
  {
    item: `Report accordingly to executive management and 
    the Board of Directors, as appropriate;`,
  },
  {
    item: `Act as an adviser in compliance matters within 
    the organisation.`,
  },
];

export const objectiveTwo = [
  {
    item: 'Legal and regulatory risk;',
  },
  {
    item: 'Reputation risk;',
  },
  {
    item: 'Litigation risk;',
  },
  {
    item: 'Risk of financial loss.',
  },
];

export const objectiveThree = [
  {
    item: `Laws, regulations and circulars governing access 
    to the financial sector and performance of Banking or financial activities,`,
  },
  {
    item: 'The prevention of money laundering and terrorism financing,',
  },
  {
    item: 'Confidentiality of information / Banking secrecy,',
  },
  {
    item: `Professional ethics, including the protection of the client interest (investor
      Protection, client information, market integrity, prevention of price
      Manipulation),`,
  },
  {
    item: 'Insider trading and market abuse,',
  },
  {
    item: `Internal code of conduct fostering an ethical 
    environment, and best practice rules established by 
    professional associations or financial organisations 
    (e.g. Stock Exchanges)`,
  },
  {
    item: `Where deemed appropriate, rules of labour, 
    social or environmental law.`,
  },
];

export const objectiveFour = [
  {
    item: `The Board of Directors is responsible for 
    defining the compliance principles to which the 
    organisation has to adhere. It has to demonstrate a 
    clear commitment by ensuring that an appropriate policy is in place and that the compliance risks are managed appropriately.`,
  },
  {
    item: `The Board of Directors shall formally approve 
    the Compliance policy recommended by Executive 
    Management. The efficiency of implementation of this 
    policy has to be evaluated on an annual basis by means 
    of a status report provided by executive management. 
    The Board of Directors may delegate this responsibility 
    to the Board Audit and Compliance Committee or to a 
    separate Board Compliance Committee appointed by it.`,
  },
  {
    item: `The compliance policy has to include the 
    Compliance Charter set up of a permanent and independent 
    Compliance function within the organisation.`,
  },
  {
    item: `The Board has to ensure that the Compliance 
    function is authorised to contact the Chief Executive 
    Officer or the Board of Directors directly, as deemed 
    necessary.`,
  },
];

export const objectiveFive = [
  {
    item: `The Executive Management being in charge of the 
    daily management of the organisation is jointly 
    responsible for implementing a compliance policy and a 
    permanent Compliance function.`,
  },
  {
    item: `On a regular basis, Executive Management has to 
    assess the adequacy of the compliance policy and to 
    verify that it is fully implemented and followed by staff.`,
  },
  {
    item: `The policy must outline the main compliance risks facing the organisation and the preventive measures to be implemented to mitigate these risks. It should further include:`,
    subItems: [
      {
        item: 'the establishment of a permanent Compliance function,',
      },
      {
        item: 'A Compliance Charter and',
      },
      {
        item: 'An ongoing training programme for staff on compliance matters.',
      },
    ],
  },
  {
    item: 'Executive management has to:',
    subItems: [
      {
        item: 'Review the policy on a regular basis;',
      },
      {
        item: `Report to the Board of Directors on matters relevant to 
        the policy and the status and efficiency of its implementation at 
        least once a year (including, but not limited to the achievement 
          of compliance-related objectives, the human and technical 
          resources needed to achieve these objectives, the main risks 
          detected by the Compliance function and the corrective action 
          taken and preventive measures to mitigate these risks);`,
      },
      {
        item: `Report promptly to the Board of Directors on any material 
        breaches of laws, regulation, rules, code of conduct and 
        standards of good practice;`,
      },
      {
        item: `Define the governing rules setting out sanctions applied 
        in case of breach of compliance, and apply such sanctions where 
        relevant.`,
      },
    ],
  },
];

export const objectiveSix = [
  {
    item: `Identify and assess the compliance risks associated with 
    the organisation’s current and proposed future business activities, 
    including new products, new business relationships and any extension 
    of operations or network on an international level;`,
  },
  {
    item: `Identify and keep an inventory available to all staff of 
    essential laws and regulation pertinent to the organisation;`,
  },
  {
    item: `Advise management on the applicable laws, regulation, 
    rules and standards and inform them about any developments in 
    these areas;`,
  },
  {
    item: `Establish written guidelines to staff and service providers on 
    the appropriate implementation of the laws, regulation, rules and 
    standards through policies and procedures (Compliance manual, code 
    of conduct)`,
  },
  {
    item: `Assess the appropriateness of internal policies, procedures 
    and guidelines, ensure a follow-up of any identified deficiencies, 
    make recommendations for amendments, where necessary, and supervise 
    the implementation of corrective measures to mitigate the identified 
    deficiencies,`,
  },
  {
    item: `Monitor compliance with internal policies, procedures and 
    guidelines by performing regular and comprehensive compliance risk 
    assessments and testing; report the results on a regular basis and 
    promptly where deemed necessary to executive management and, if 
    necessary, the Board of Directors`,
  },
  {
    item: `Centralise all information on compliance-related issues 
    (ex.: breach of regulation, non-respect of procedures, conflict of 
    interest),`,
  },
  {
    item: `Educate staff with respect to compliance with the applicable 
    laws, rules and standards and act as advisor on compliance queries 
    from staff members`,
  },
  {
    item: `Iiaise with relevant external bodies and regulators on 
    compliance matters; exercise any specific legal responsibilities 
    such as reporting suspicious transactions related to money laundering 
    and the fight against terrorism financing;`,
  },
  {
    item: `Establish and/or supervise appropriate compliance checks and 
    controls. Therefore, members of the Compliance function should have a 
    high professional. Competence in the domain of Banking and financial 
    activities and of the applicable best practice.`,
  },
];

export const objectiveSeven = [
  {
    item: `Organising and managing their business and operational areas in a proper
    Professional manner,`,
  },
  {
    item: `Implementing the compliance policy in their respective business and operational areas,`,
  },
  {
    item: `Reporting all breaches thereof promptly to the Compliance Officer.`,
  },
];

export const objectiveEight = [
  {
    item: `From an organisational viewpoint, the Compliance 
    function is hierarchically linked and report directly 
    to executive management. It is authorised to perform 
    its roles and responsibilities at its own initiative. 
    Escalation to Board members or Board committees is 
    allowed.`,
  },
  {
    item: `In order to warrant objectivity, staff members 
    of the Compliance function are not authorised to assume 
    commercial or operational responsibilities in the areas 
    they control. Moreover, as guideline objectivity is 
    presumed to be impaired when staff of the Compliance 
    function controls processes or activities for which they 
    previously had authority or responsibility during the 
    past year. However, providing professional consultancy 
    in these processes or activities does not adversely 
    affect objectivity.`,
  },
];

export const objectiveNine = [
  {
    item: 'Compliance task description Business / operational',
  },
  {
    item: 'Function',
  },
  {
    item: 'Responsible person',
  },
  {
    item: 'Financial statements',
  },
  {
    item: 'Prepare and ensure prompt rendition to CBN/FMBN on a monthly, quarterly and annually',
  },
  {
    item: 'Head of Accounts/Financial Control',
  },
  {
    item: 'Financial statements',
  },
  {
    item: 'Prepare and Legal ensure prompt rendition to SEC annually',
  },
  {
    item: 'Head of Accounts/Financial Control and Head of Legal',
  },
  {
    item: 'Quarterly financial forecast',
  },
  {
    item: 'Prepare and Legal ensureprompt rendition to NSE quarterly',
  },
  {
    item: 'Head of Accounts/Financial Control and Head of Legal',
  },
  {
    item: 'Anti-Money Laundering',
  },
  {
    item: 'Prepare CTR,STR,PEP, reports on weekly, monthly, quarterly, semi-annually and annually to the NFIU (EFCC), NDIC and CBN',
  },
  {
    item: 'Head of Compliance',
  },
  {
    item: 'P.A.Y.E.',
  },
  {
    item: 'Ensures prompt remittance of P.A.Y.E. deduction to LIRS on a monthly basis',
  },
  {
    item: 'Head of Accounts/Financial Control',
  },
  {
    item: 'Pension Fund Contribution Deductions',
  },
  {
    item: 'Ensures remittance to various staff Pension Manages on a monthly basis by second week of the following month of deduction from staff',
  },
  {
    item: 'Head of Accounts/Financial Control',
  },
  {
    item: 'W.H.T.',
  },
  {
    item: 'Ensures remittance to LIRS & FIRS on a monthly basis',
  },
  {
    item: 'Head of Accounts/Financial Control',
  },
  {
    item: 'Statement of Affairs (S.636)',
  },
  {
    item: 'Ensures submission of statements on Bi-annual(February and August) every year',
  },
  {
    item: 'Head Legal',
  },
  {
    item: 'P& L and Balance Sheet',
  },
  {
    item: 'Ensures submission of documents to NDIC on a quarterly basis',
  },
  {
    item: 'Head of Accounts/Financial Control',
  },
  {
    item: 'Annual Subscription to ITF',
  },
  {
    item: 'Ensures 1% of annual pay roll is remitted to ITF 3 months (March 31st) after the end of any financial year ending December.',
  },
  {
    item: 'ED, Admin/Head HR and Admin',
  },
  {
    item: 'National Housing Fund',
  },
  {
    item: 'Ensures remittance to FMBN on a monthly basis by second week of the following month of deduction from staff',
  },
  {
    item: 'Head of Accounts/Financial Control',
  },
  {
    item: 'Employees Compensation Act (NSITF)',
  },
  {
    item: 'Ensures 1% of total monthly payroll is remitted to NSITF annually on 1st January of every year',
  },
  {
    item: 'ED, Admin/Head HR and Admin',
  },
  {
    item: 'Customers’ Complaints',
  },
  {
    item: 'Ensures submission of such report on a monthly basis to CBN, 5th day of the following month',
  },
  {
    item: 'Head of Compliance',
  },
  {
    item: 'Environment and Safety',
  },
  {
    item: 'Ensure safety of the working environment both external and internal in line with government regulations.',
  },
  {
    item: 'ED, Admin/Head HR and Admin/Head of Estate',
  },
  {
    item: 'Code of Corporate Governance',
  },
  {
    item: 'Ensures compliance with Code of Corporate Governance',
  },
  {
    item: 'Company Secretary/Head of Legal',
  },
  {
    item: 'Prudential Ratios and loan creditors',
  },
  {
    item: 'Ensures compliance with CBN prudential and other loan creditors ratios',
  },
  {
    item: 'Head of Risk Management, GM Operations and Head of Assets & Liabilities Management Department.',
  },
  {
    item: 'CBN/NFIU Account opening and KYC Requirements',
  },
  {
    item: 'Ensures proper monitoring and training',
  },
  {
    item: 'Head of Compliance',
  },
  {
    item: 'Group life Insurance',
  },
  {
    item: 'Ensures the policy is in place as at when required or annually',
  },
  {
    item: 'ED, Admin/Head HR and Admin/Head of Estate',
  },
  {
    item: 'Monitoring of Employee Conduct AML/TF Requirements to NFIU and CBN',
  },
  {
    item: 'Ensures submission of report on a quarterly basis to NFIU',
  },
  {
    item: 'Head of Compliance',
  },
];

export const objectiveTen = [
  {
    item: `The Compliance function remains responsible for 
    the delegated compliance-related tasks.`,
  },
  {
    item: `The responsible person mandated in the 
    business/operational function to perform such 
    delegated tasks have to formally and immediately 
    report any findings, assessments and identified risks 
    to the Compliance Officer who has an instruction right 
    with regard to this person in relation to the delegated 
    tasks.`,
  },
  {
    item: `The Compliance Officer has free access to any 
    checklists or other work papers produced by the 
    responsible person in order to document the 
    compliance-related tasks.`,
  },
  {
    item: `For these specific tasks, the responsible person 
    is not authorised to assume commercial or operational 
    responsibilities in the areas he controls, i.e. the 
    delegated tasks must be compatible with the other tasks 
    performed by such person.`,
  },
  {
    item: `The delegated processes must be duly documented 
    by means of internal procedures agreed between the 
    Compliance and the business/operational functions.`,
  },
];

export const objectiveEleven = [
  {
    item: `Reviewing the systems in place for compliance 
    with statutory/regulatory and supervisory requirements;`,
  },
  {
    item: `Review of integrity, reliability and accuracy of 
    accounting and financial reporting systems;`,
  },
  {
    item: `Keeping under review the scope and results of 
    the audit exercise, its cost effectiveness and the independence and objectivity of external auditors;`,
  },
  {
    item: `Reviewing with external auditors and management, 
    the adequacy and efficacy of internal controls;`,
  },
  {
    item: `Reviewing the audit reports of the Internal 
    Auditor and making appropriate recommendations to Board 
    and Management;`,
  },
  {
    item: `Reviewing and making recommendations on the 
    Bank’s operations manual;`,
  },
  {
    item: `Ensuring and maintaining effectiveness of 
    business and operational risk acceptance criteria;`,
  },
  {
    item: `Reviewing the systems in place for monitoring 
    risk, control and compliance with applicable 
    regulations;`,
  },
  {
    item: `Ensuring the establishment of audit and 
    accounting schedules and their time effective 
    implementation.`,
  },
];

export const auditComposition = [
  {
    item: 'Such number of persons as the Board may determine.',
  },
  {
    item: `Members must possess business knowledge/skills 
    and familiarity with Banking and finance, credit risk 
    management, Banking operations, risk management, 
    accounting practices and concepts;`,
  },
  {
    item: `The Bank’s Heads of Audit, Risk Management, 
    Compliance and Financial Control will sit in attendance 
    at meetings of the Committee;`,
  },
];

export const informationRequirements = [
  {
    item: 'Before the Meeting',
    subItems: [
      {
        item: 'Notification of Meeting',
      },
      {
        item: 'Meeting Agenda',
      },
      {
        item: 'Memoranda/Discussion Papers',
      },
      {
        item: 'Bank wide Exception Report',
      },
      {
        item: 'Report on Risk compliance levels',
      },
      {
        item: 'Regulator’s report (if any)',
      },
      {
        item: 'Report on other special investigations on the operations of the Bank',
      },
    ],
  },
  {
    item: 'After the Meeting',
    subItems: [
      {
        item: `Minutes of meeting – 
        To be delivered along with notice of the next meeting.`,
      },
    ],
  },
];

export const boardScope = [
  {
    item: 'To set the criteria for the nomination and selection of directors and executive directors of the Bank.',
  },
  {
    item: 'To review succession planning and make recommendations to the Board.',
  },
  {
    item: 'To review the performance of the Managing Director every year and to decide his/her compensation package.',
  },
  {
    item: 'To review the performance of Directors.',
  },
  {
    item: 'To approve the appointment/promotion of officers of the company from the rank of Assistant General Manager and to handle issues arising from their disengagement.',
  },
  {
    item: 'Determining disciplinary action against erring officers from the rank of Assistant General Manager.',
  },
  {
    item: 'Developing a policy on the remuneration of directors.',
  },
  {
    item: 'Reviewing recommendations on remuneration of officers of the Bank from the rank of Assistant General Manager.',
  },
  {
    item: 'Reviewing and making recommendations to the Board for approval of the company’s organizational structure and any proposed amendments.',
  },
  {
    item: 'Ensuring compliance with the applicable codes of corporate governance.',
  },
  {
    item: 'Such other duties that may be assigned to it by the Board.',
  },
];

export const strategyScope = [
  {
    item: `To develop specific strategies that will help 
    the company achieve its vision of being the number one 
    primary mortgage Bank in Nigeria.`,
  },
  {
    item: 'To develop business and market segment strategies.',
  },
  {
    item: 'To determine discriminating competencies that will establish the Bank as a clear market leader in terms of service delivery.',
  },
  {
    item: 'To advise on the development of new business models and structural adjustments that will improve performance on key indices such as employee productivity, branch efficiency, return on equity, and cost to income rates to exceed industry averages.',
  },
  {
    item: 'To advise the Board on the maintenance of key strategies and business alliances for the attainment of the above goals.',
  },
  {
    item: 'To develop short to long term plans/strategies/financial plans, forecast etc.',
  },
];

export const reporting = [
  {
    item: 'The Board shall regularly provide the shareholders with a transparent, balanced, and clear report of the Bank’s performance, position and prospects in line with statutory requirements;',
  },
  {
    item: 'The Executive Management of the Bank shall provide all members of the Board with a transparent, balanced, and clear report of the Bank’s performance, position, and prospects on a regular basis;',
  },
  {
    item: 'The Board shall ensure that an objective and professional relationship is maintained with External Auditors;',
  },
  {
    item: 'The Board shall include a statement in the Annual Report confirming that the Bankis a going concern with supporting assumptions and qualifications as necessary;',
  },
  {
    item: 'The Directors shall explain their responsibility for preparing the accounts and there shall be a statement by the Auditors about their reporting responsibilities as required under the provisions of the Companies and Allied Matters Act;',
  },
  {
    item: 'The Board’s responsibility to present a balanced and understandable report of the Bank’s performance extends to interim and other price-sensitive public reports.',
  },
];

export const financial = [
  {
    name: 'Abbey 2009 Annual Report Account',
    path: 'Abbey_2009_Annual_Report_Account.pdf',
  },
  {
    name: 'Abbey 2010 Annual Report Account',
    path: 'Abbey_2010_Annual_Report_Account.pdf',
  },
  {
    name: 'Abbey 2011 Annual Report Account',
    path: 'Abbey_2011_Annual_Report_Account.pdf',
  },
  {
    name: 'Abbey 2012 Annual Report Account',
    path: 'Audited financial Statement 2012.pdf',
  },
  {
    name: 'Abbey 2013 Annual Report Account',
    path: 'Abbey_2013_Annual_Report_Account.pdf',
  },
  {
    name: 'Abbey 2014 Annual Report Account',
    path: 'Abbey_2014_Annual_Report_Account.pdf',
  },
  {
    name: 'Abbey 2015 Annual Report Account',
    path: 'Abbey_2015_Annual_Report_Account.pdf',
  },
  {
    name: 'Abbey 2016 Annual Report Account',
    path: 'Abbey_2016_Annual_Report_Account.pdf',
  },
  {
    name: 'Abbey 2017 Annual Report Account',
    path: 'Abbey_2017_Annual_Report_Account.pdf',
  },
  {
    name: 'Abbey 2018 Annual Report Account',
    path: 'Abbey_2018_Annual_Report_Account.pdf',
  },
  {
    name: 'Abbey 2019 Annual Report Account',
    path: 'Abbey_2019_Annual_Report_Account.pdf',
  },
  {
    name: 'Abbey 2020 Annual Report Account',
    path: 'Abbey_2020_Annual_Report_Account.pdf',
  },
  {
    name: 'Abbey 2021 Annual Report Account',
    path: 'Abbey_2021_Annual_Report_Account.pdf',
  }
]

export const notice = [
  {
    name: 'Publication Of AGM 2022',
    path: 'Publication_of_AGM_2022.pdf',
  }
 
]

export const policy = [
  {
    name: 'Policy 1'
  },
  {
    name: 'Policy 2'
  },
  {
    name: 'Policy 3'
  },
  {
    name: 'Policy 4'
  }
]

export const disclosure = [
  {
    name: 'Insider Trading Policy',
    path: 'Insider_Trading_Policy.pdf'
  }
]

export const wireFeatures = [
  {
    img: Check,
    item: `An Environmental, Social and Governmental product that 
    provides construction finance to female-owned/managed real 
    estate companies or projects`,
  },
  {
    img: Check,
    item: `Requires a current account with the bank`
  },
  {
    img: Check,
    item: `Access to Construction Finance at a reduced interest rate`
  },
  {
    img: Check,
    item: `Minimum 20% Equity contribution`
  },
  {
    img: Check,
    item: `3-6 Months moratorium depending on the timing of cash flow`
  },
  {
    img: Check,
    item: `Maximum repayment tenure of 3 years`
  },
  {
    img: Check,
    item: `Collateral required`
  }
]

export const wireBenefits = [
  {
    img: Check,
    item: `Reduced Interest rates`
  },
  {
    img: Check,
    item: `Access to training and seminars on real estate <br /> 
    finance and other financial modelling structures`
  },
  {
    img: Check,
    item: `Availability of mortgage loans to off-takers, <br /> 
    subject to each off-taker meeting our lending criteria`
  }
]

export const wireRequirements = [
  {
    img: Check,
    item: `Open a WIRE Account`
  },
  {
    img: Check,
    item: `Completed Loan Application Form`
  },
  {
    img: Check,
    item: `Last Three years audited Accounts`
  },
  {
    img: Check,
    item: `12 Months Account Statements`
  },
  {
    img: Check,
    item: `Copy of Certificate of Authentication`
  },
  {
    img: Check,
    item: `Valid Tax Clearance Certificate`
  },
  {
    img: Check,
    item: `Board Resolution approving the project`
  },
  {
    img: Check,
    item: `All relevant titles for the project site`
  }
]